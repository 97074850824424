/* src/App.css */

/* Global styles */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;  
  }



/* Navbar styling */
.nav{
  width: 100%;
  background-color:rgba(14, 34, 64, 1);
  box-sizing: border-box;
  border-bottom: 3px solid rgba(3, 214, 163, 1);
}


.navbar {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 7.5rem;
  background-color: rgba(14, 34, 64, 1);
  color: black;
  position: relative;
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
}

.navbar {
  background-color: rgba(14, 34, 64, 1);
  width: 100%;
  position: relative;
  box-sizing: border-box;
  
}

/* Content wrapper to center the navbar content */
.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px; /* Centering limit for large screens */
  margin: 0 auto; /* Center the content */
  width: 100%;
  
}



/* Styling for left section of the navbar */
.navbar-left h1 {
  margin: 0;
  font-size: 1.5rem;
  color: white;
}

.powered-by {
  font-size: 0.9rem;
  color: rgba(3, 214, 163, 1);
  font-weight: 600;
  margin: 0;
}

/* Styling for right section of the navbar */
.navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  
}

/* Styling for dropdown container */
.dropdown {
  /* position: relative; */
  margin-right: 1rem;
}

/* Styling for dropdown toggle button */
.dropdown-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  /* position: relative; */
}

/* Triangle for dropdown toggle */
.triangle {
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s ease;
  color: rgba(3, 214, 163, 1);
}



.rotate {
  transform: rotate(180deg); /* Rotate the triangle 180 degrees */
}

/* Dropdown menu styles for large screens */
.dropdown-menu {
  position: absolute;
  background-color: rgba(239, 239, 239, 0.8);
  padding:1rem 1rem;
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(4, 1fr); /* Four equal-width columns */
  gap: 1rem; /* Space between items */
  width: 100%; /* Full viewport width */
  left: 0; /* Align to the left edge of the viewport */
  top: 78px; /* Position just below the navbar */
  box-sizing: border-box; /* Include padding in width calculation */
  z-index: 9998; /* Ensure it is above other content */
}

/* Dropdown items styling */
.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: left; /* Align content to the left */
  text-decoration: none;
  color: rgba(14, 34, 64, 1);
  padding: 1rem; /* Adjust padding as needed */
  background-color: white;
  text-align: center;
  box-sizing: border-box; /* Include padding in width calculation */
  height: auto;
  overflow: hidden; /* Hide overflow to maintain size */
  border-radius: 10px;
}

/* Ensure images and text fit within item size */
.dropdown-item img {
  width: 40px; /* Adjust to fit the item size */
  height: auto; /* Maintain aspect ratio */
  margin-right: 0.5rem; /* Space between image and text */
  filter: brightness(0) saturate(100%) invert(17%) sepia(34%) saturate(623%) hue-rotate(175deg) brightness(100%) contrast(101%);

}

.dropdown-item span {
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
  text-align:left;
}

.build-park-btn {
  padding: 0.5rem 1rem;
  background-color:rgba(14, 34, 64, 1);
  border:2px solid rgba(3, 214, 163, 1);
  cursor: pointer;
  color:rgba(239, 239, 239, 1);
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;

}

/* Hamburger menu for small screens */
.hamburger {
  display: none;
  background:none;
  color: white;
  font-size: 2.5rem;
  cursor: pointer;
  font-weight: bolder;
  border: none;

}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-color:rgba(239, 239, 239, 1);
  z-index: 9999; /* Ensure it is above everything else */
  display: flex;
  flex-direction: column;
  overflow:scroll; /* Allow scrolling for hamburger menu content */
  align-items: center; /* Center items horizontally */
  padding-left: 15px;
  
  

  
}

.close-btn {
  background: none;
  border: none;
  color:rgba(14, 34, 64, 1);
  font-size: 3.5rem; /* Increased size */
  cursor: pointer;
  align-self: flex-end; /* Ensures it aligns to the top-right */
  margin-right: 2.5rem; /* Add some margin to move it away from the right edge */
  margin-top: 0rem; /* Add margin from the top */
  margin-bottom: 2rem;
  z-index: 10000; /* Ensure it stays on top */
}

.hamburger-dropdowns {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%; /* Full width of the screen */
  gap: 10px;

  
  
}

/* Ensure dropdown menu styles for small screens are centered */
.hamburger-dropdowns .dropdown-menu.full-width {
  flex-direction: column; /* Stack items vertically */
  width: 100%; /* Full width of the screen */
  height: auto;
  text-align: center;
  position: static; /* Ensure static position for small screens */
  z-index: 9997; /* Ensure it is below the close button but above the overlay */
}

/* Responsive Design */

@media (max-width: 922px) {
  .navbar{
    padding: 5px 5px;
  }

  .navbar-left h1 {
    margin: 0;
    font-size: 1.3rem;
    color: white;

  }

  
  
  .powered-by {
    font-size: 0.8rem;
    color: rgba(3, 214, 163, 1);
    font-weight: 600;
    margin: 0;
  
  }

  .navbar-right {
    display: none;
  }

  .dropdown-toggle{
    color:rgba(14, 34, 64, 1);
    font-size: 1.3rem;
    font-weight: bold;
    /* width: 100px; */
    justify-items: auto;
    padding: 10px;
  }

  .hamburger {
    display: block;
  }

  /* Ensure dropdown menu styles for small screens are vertically aligned and centered */
  .dropdown-menu {
    width: 100%;
    padding-right: 30px;
    display: flex; /* Flex layout for small screens */
    flex-direction: column; /* Stack items vertically */
    position: absolute;
    top: 40px; /* Adjust top position to be below the navbar */
    left: 0; /* Align to the left edge */
    right:0; /* Align to the right edge */
    /* margin: 0 auto; */
    box-sizing: border-box; /* Include padding in width calculation */
    overflow-y: auto; /* Allow vertical scrolling if needed */
    max-height: calc(100vh); 
    z-index: 9997; /* Ensure it is below the overlay but above other content */
    border-right: 10px;
    



  }

  .dropdown-item {
    /* flex: 1 0 auto;  */
    display: block; /* Ensure items stack vertically */
    display: flex;
    justify-content:left;
    
  
    
  }

  .dropdown-item img{
    width: 50px;
    height: auto;
    padding-right: 10px;
    

   
  }

  .build-park-btn {
    width: 200px;
    height: 50px;
    font-size: 1.3rem;
    margin-bottom: 15px;
   
  
  }

  .social-icons1 a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons1 img{
    display: flex;
    justify-content: center;
    width: 30px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%);  
  }

  
}


  
  /* Video section starts */
  .video-container {
    width: 100%;
    height: 80vh; /* Make the container occupy the full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black; /* In case the video doesn't fully cover */
    /* position: relative; */
    overflow: hidden; 
    
  }

  
  /* Video element styling */
  video {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
    .video-container {
      height: 40vh; /* Adjust the height for smaller screens */
    }
  }
  
  @media (max-width: 922px) {
    .video-container {
      height: 40vh; /* Adjust the height for smaller screens */
    }
  }
  
  
  
  @media (max-width: 768px) {
    .video-container {
      height: 40vh; /* Adjust the height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .video-container {
      height: 40vh; /* Further adjust the height for very small screens */
    }
  }
  
  /* Video section ends  */
  
  
  /* solution section starts */
  .sol {
    background-color: rgba(250, 249, 254, 1);
    width: 100%;
    padding: 20px 0; /* Adjust as needed for vertical spacing */
}

  
  .solution {
    padding: 20px 150px;
    text-align: center;
    background:rgba(250, 249, 254, 1);
    max-width: 1520px;
    margin: 0 auto;
    

  }
  
  .solution .title1 {
    font-size: 2em;
    margin-bottom: 0px;
    color: rgba(14, 34, 64);
  }

  .solution .title1 span{
    color:rgba(3, 214, 163, 1) ;
  }
  
  .solution .subtitle1 {
    font-size: 1.2em;
    margin-bottom: 50px;
    text-decoration-color:black;
    font-weight: 550;
    color: rgba(14, 34, 64);
  }
  
  .gridd {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 5 items per row */
    gap: 20px;
    justify-content: center; /* Center items horizontally in the grid */
    justify-items: center;   /* Center items horizontally in each grid cell */
    margin: 0 auto; /* Center .gridd within its parent */
  }
  
  /* Grid Item Styles */
  .gridd-item1, .gridd-item2, .gridd-item3, .gridd-item4, .gridd-item5, .gridd-item6, .gridd-item7, .gridd-item8 {
    background:rgba(255, 255, 255, 1);
    overflow: hidden;
    text-align: center;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(14, 34, 64, 0.1);
    transition: background-color 0.3s ease;
    height: auto;
    border-radius: 10px;
    width: 200px;
    height: auto;
  }
  
  /* Image Styling */
  .gridd-item1 img, 
  .gridd-item2 img, 
  .gridd-item3 img, 
  .gridd-item4 img, 
  .gridd-item5 img,
  .gridd-item6 img,
  .gridd-item7 img,
  .gridd-item8 img {
    width: 40%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, background-color 0.3s ease, color 0.3s ease
  }

  .gridd-item1 img{
    width: 40%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
    transition: filter 0.3s ease;

  }

  .gridd-item2 img{
    width: 45%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
    transition: filter 0.3s ease;

  }

  .gridd-item3 img{
    width: 45%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
    transition: filter 0.3s ease;

  }

  .gridd-item5 img{
    width: 58%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
    transition: filter 0.3s ease;

  }

  .gridd-item6 img{
    width: 43%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
    transition: filter 0.3s ease;

  }

  .gridd-item8 img{
    width: 45%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
    transition: filter 0.3s ease;
    
    

  }
  
  
  
  /* Text Styling */
  .gridd-item1 h4, 
  .gridd-item2 h4, 
  .gridd-item3 h4, 
  .gridd-item4 h4, 
  .gridd-item5 h4,
  .gridd-item6 h4, 
  .gridd-item7 h4, 
  .gridd-item8 h4 {
    font-size: 1.1em;
    color:rgba(14, 34, 64, 1);
    margin: 0;
    transition: color 0.3s ease;
  }
  
  /* Hover Effects */
  .gridd-item1:hover, 
  .gridd-item2:hover, 
  .gridd-item3:hover, 
  .gridd-item4:hover, 
  .gridd-item5:hover,
  .gridd-item6:hover,
  .gridd-item7:hover,
  .gridd-item8:hover {
    background: rgba(14, 34, 64, 1);
    transform: translateY(-3px);
  }
  
  .gridd-item1:hover img, 
  .gridd-item2:hover img, 
  .gridd-item3:hover img, 
  .gridd-item4:hover img, 
  .gridd-item5:hover img,
  .gridd-item6:hover img,
  .gridd-item7:hover img,
  .gridd-item8:hover img {
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%);
   
    
  }
  
  .gridd-item1:hover h4, 
  .gridd-item2:hover h4, 
  .gridd-item3:hover h4, 
  .gridd-item4:hover h4, 
  .gridd-item5:hover h4,
  .gridd-item6:hover h4, 
  .gridd-item7:hover h4, 
  .gridd-item8:hover h4 {
    color: white;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .gridd {
      grid-template-columns: repeat(4, 1fr); /* 4 items per row on smaller screens */
    }
  
    .gridd-item1 img, 
    .gridd-item2 img, 
    .gridd-item3 img, 
    .gridd-item4 img, 
    .gridd-item5 img,
    .gridd-item6 img,
    .gridd-item7 img,
    .gridd-item8 img {
      width: 45%;
    }
  }
  
  @media (max-width: 992px) {
    .gridd {
      grid-template-columns: repeat(3, 1fr); /* 3 items per row on smaller screens */
    }
  
    .gridd-item1 img, 
    .gridd-item2 img, 
    .gridd-item3 img, 
    .gridd-item4 img, 
    .gridd-item5 img,
    .gridd-item6 img,
    .gridd-item7 img,
    .gridd-item8 img {
      width: 55%;
    }
  }
  
  @media (max-width: 768px) {
    .gridd {
      grid-template-columns: repeat(2, 1fr); /* 2 items per row on smaller screens */
    }
  
    .gridd-item1 img, 
    .gridd-item2 img, 
    .gridd-item3 img, 
    .gridd-item4 img, 
    .gridd-item5 img,
    .gridd-item6 img,
    .gridd-item7 img,
    .gridd-item8 img {
      width: 45%;
    }

    .gridd-item1 img{
      width: 42%;
    }

    .gridd-item5 img{
      width: 60%;
    }

    .gridd-item8 img{
      width: 52%;
    }
  
    .gridd-item1 h4, 
    .gridd-item2 h4, 
    .gridd-item3 h4, 
    .gridd-item4 h4, 
    .gridd-item5 h4,
    .gridd-item6 h4,
    .gridd-item7 h4,
    .gridd-item8 h4 {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {

    .solution {
      padding: 20px 40px;
      text-align: center;
      background:rgba(250, 249, 254, 1);
    }

    .gridd {
      grid-template-columns: 1fr; /* 1 item per row on very small screens */
      width: 80%;
      height: auto;
    }
  
    .gridd-item1 img, 
    .gridd-item2 img, 
    .gridd-item3 img, 
    .gridd-item4 img, 
    .gridd-item5 img,
    .gridd-item6 img,
    .gridd-item7 img,
    .gridd-item8 img {
      width: 40%;
    }

     
  .solution .title1 {
    font-size:1.2rem;
    
  }
  
  .solution .subtitle1 {
    font-size:0.9rem;
    
  }    
  
    .gridd-item1 h4, 
    .gridd-item2 h4, 
    .gridd-item3 h4, 
    .gridd-item4 h4, 
    .gridd-item5 h4,
    .gridd-item6 h4,
    .gridd-item7 h4,
    .gridd-item8 h4 {
      font-size: 0.9em;
    }
  
    .gridd-item1, .gridd-item2, .gridd-item3, .gridd-item4, .gridd-item5, .gridd-item6, .gridd-item7, .gridd-item8 {
      width: 100%;
      padding: 10px;
    }
  }

  /* Solution section ends  */
  
  /* content section starts */
.con{
  background-color:rgba(14, 34, 64, 1);
  width: 100%;
  padding: 20px 0; /* Adjust as needed for vertical spacing */
}


.content-section {
  padding: 20px 100px;
  background-color:rgba(14, 34, 64);
  text-align: center;
  max-width: 1520px;
  margin: 0 auto;
}

.content-section .title {
  font-size: 2.2rem;
  margin-bottom: 10px;
  color: white;

}

.content-section .title span{
  color: rgba(3, 214, 163, 1);
}

.subtitlee {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: white;
}

.content-box-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 20px;
  
}

.content-box {
  border-radius: 3px;
  /* background-color: rgba(239, 239, 239, 1); */
  position: relative; /* Required for positioning the button correctly */
  text-align: left;
  overflow: hidden; /* Ensure content doesn't overflow */
  /* padding: 20px; Add padding if needed to space content from edges */
  border: 2px solid rgba(3, 214, 163, 1) ;
  border-radius: 10px;
}


.content-box img {
  width: 100%;
  height: 325px;
}


.content-box h3 {
  font-size: 22px;
  margin-bottom: 10px;
  padding-left: 15px;
  

  
}

.content-box p {
  font-size: 14px;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 40px;
  color: #2196f3; 
  

}



.content-box h3,
.content-box p { 
   
  transition: color 0.3s ease;
}

.content-box button {
  font-size: 1rem;
  background-color: black;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 160px;
  height: 40px;
  font-weight: bold;
  position: absolute; /* Position button absolutely */
  bottom: 15px; /* Adjust to position button from the bottom of the content-box */
  left: 15px; /* Adjust to position button from the right edge */
  border: 2px solid rgba(3, 214, 163, 1);

}

.content-box:hover{
  transform: translateY(-3px);
}

.content-box button:hover {
  background-color: white;
  color: black;
  transform: translateY(-3px);
}

/* Responsive design */

/* Adjust for tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .content-box-wrapper {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }

  .content-section .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .content-box button {
    width: 180px; /* Adjust button width for tablets */
    height: 35px; /* Adjust button height for tablets */
  }
}

/* Stack items on small screens */
@media (max-width: 767px) {

  .content-section{
    padding: 20px 20px;
  }
  .content-box-wrapper {
    grid-template-columns: 1fr; /* Single column on small screens */
    padding: 0 10px; /* Reduce padding to fit small screens */
  }

  .content-box h3{
    font-size: 1.2rem;
  }

  .content-box img{
    height: 250px;
  }

  .content-section .title {
    font-size: 1.2rem; /* Smaller title font size */
  }

  .subtitlee {
    font-size: 0.9rem; /* Smaller subtitle font size */
  }

  .content-box {
    padding: 0px; /* Reduce padding inside the content box */
  }

  .content-box button {
    width: 150px; /* Adjust button width for small screens */
    height: 35px; /* Adjust button height for small screens */
  }
}  
 
/* Content section ends  */
  

/* Slider section starts   */
  
.marquee-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color:white; 
}

.title-container {
  padding-top: 40px;
  width: 100%;
  text-align: center;
  /* z-index: 10; Ensure the title is above the scrolling images */
  text-decoration-color: rgba(14, 34, 64, 1);

}

.title-container h1{
  color: rgba(14, 34, 64, 1);
}

.title-container h1 span{
  color: rgba(3, 214, 163, 1);
}

.title-container h2{
  color: rgba(14, 34, 64, 1);
}


.marquee {
  display: flex;
  width: max-content; /* Ensures the div is wide enough */
  animation: scroll-marquee 50s linear infinite;
  animation-play-state:running;
  cursor: grab;
  white-space: nowrap; 
  padding-bottom: 40px;
  
}

/* Hide scrollbar for manual scrolling */
.marquee::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.marquee {
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}

.marquee img {
  width: 350px; /* Adjust the width according to your needs */
  height: auto;
  margin: 20px 10px; /* Adjust the space between images */
  cursor: pointer;
  border-radius: 10px;
}

@keyframes scroll-marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


@media (max-width: 768px) {
  .title h1 {
    font-size: 1.4rem; /* Smaller for mobile devices */
  }
  
  .subtitle {
    font-size: 1rem; /* Smaller for mobile devices */
  }

  .marquee img {
    width: 300px; /* Smaller for mobile devices */
  }
}

@media (max-width: 576px) {
  .title-container h1 {
    font-size: 1.2rem; /* Smaller for very small screens */
    padding: 10px;
  }
  
  .title-container h2 {
    font-size: 0.9rem; /* Smaller for very small screens */
  }

  .marquee img {
    width: 220px; /* Smaller for very small screens */
  }
}

 /* Slider section ends  */


 
  /* park section starts */
  .parkk{
    background-color: rgba(14, 34, 64, 1);/* your desired background color */
    width: 100%;
    padding: 20px 0; /* Adjust as needed for vertical spacing */
  }


  .park {
    max-width: 1520px;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    background-color: rgba(14, 34, 64, 1);
    display: flex;
    justify-content: center; /* Horizontal center */
    align-items: center;    /* Vertical center */
    flex-direction: column;
    text-align: center;
    padding: 20px; /* Add some padding for smaller screens */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
  
  .park h1 {
    color: #f8f8f8;
    line-height: 1.2; /* Adjust line-height if needed */
    margin: 0;        /* Remove default margin */
    font-size: 2.5rem; /* Adjust font size for better responsiveness */
  }

  .park h1 span{
    color: rgba(3, 214, 163, 1);

  }
  
  .park p {
    color: #f8f8f8;
    line-height: 1.2; /* Adjust line-height if needed */
    margin: 0;        /* Remove default margin */
    margin-top: 5px;
    margin-bottom: 25px;  /* Add some space if needed between h3 and p */
    font-size: 1.2rem; /* Adjust font size for better readability */
    padding: 10px 150px;
    
  }
  
  .park img {
    width: 33%; /* Adjust width to be responsive */
    height: auto;  /* Maintain aspect ratio */
    margin-top: 20px; /* Space between the text and the image */
  }

  .park img:hover{
    transform: translateY(-3px);
  }
  
  .park button {
    font-weight: bold;
    width: 25%; /* Adjust width to be responsive */
    max-width: 200px; /* Set a maximum width to maintain layout */
    height: auto; /* Adjust height based on content */
    font-size: 1rem; /* Adjust font size for better readability */
    padding: 10px; /* Add some padding for better appearance */
    cursor: pointer; /* Show pointer cursor on hover */
    border: 2px solid rgba(3, 214, 163, 1); /* Remove default border */
    border-radius: 10px; /* Rounded corners */
    color: white;
    background-color: rgba(14, 34, 64, 1);
  }

  .park button:hover{
    transform: translateY(-3px);
  }
  
  
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .park h1 {
      font-size: 2rem; /* Smaller font size for medium screens */
    }
  
    .park p {
      font-size: 1rem; /* Smaller font size for medium screens */
    }
  
    .park img {
      width: 70%; /* Slightly larger width for medium screens */
    }
  
    .park button {
      width: 40%; /* Adjust width for medium screens */
      font-size: 0.9rem; /* Slightly smaller font size for medium screens */
    }
  }
  
  @media (max-width: 992px) {
    .park h1 {
      font-size: 1.8rem; /* Smaller font size for smaller screens */
    }
  
    .park p {
      font-size: 0.9rem; /* Smaller font size for smaller screens */
    }
  
    .park img {
      width: 80%; /* Larger width for smaller screens */
    }
  
    .park button {
      width: 40%; /* Adjust width for smaller screens */
      font-size: 0.8rem; /* Smaller font size for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .park {
      height: auto; /* Allow height to adjust based on content */
    }
  
    .park h1 {
      font-size: 2rem; /* Smaller font size for mobile screens */
    }
  
    .park p {
      font-size: 1.2rem; /* Smaller font size for mobile screens */
    }
  
    .park img {
      width: 90%; /* Almost full width for mobile screens */
    }
  
    .park button {
      width: 40%; /* Wider button for mobile screens */
      font-size: 0.7rem; /* Smaller font size for mobile screens */
    }
  }
  
  @media (max-width: 576px) {
    .park h1 {
      font-size: 1.2rem; /* Smaller font size for very small screens */
    }
  
    .park p {
      font-size: 0.9rem; /* Smaller font size for very small screens */
      padding: 5px 20px;
    }
  
    .park img {
      width: 90%; /* Full width for very small screens */
    }
  
    .park button {
      width: 40%; /* Wider button for very small screens */
      font-size: 0.6rem; /* Smaller font size for very small screens */
    }
  }

 /* park section ends   */
  
  /* Customer section starts */
.cust{
  background-color:rgba(250, 249, 254, 1);
  width: 100%;
  padding: 20px 0; /* Adjust as needed for vertical spacing */

}


.Customer {
  padding: 20px;
  text-align: center;
  padding-bottom: 100px;
  background-color:rgba(250, 249, 254, 1);
  max-width: 1520px;
  margin: 0 auto;
}

.Customer .title {
  font-size: 2em;
  margin-bottom: 0;
  color:rgba(14, 34, 64, 1);
}

.title span{
  color: rgba(3, 214, 163, 1);
}

.Customer .subtitle {
  font-size: 1.2em;
  color:rgba(14, 34, 64, 1);
  margin-bottom: 50px;
  font-weight: 550;
}

/* Centering the grid container */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Automatically adjusts to fit the container */
  gap: 20px; /* Space between the items */
  justify-content: center; /* Center the grid items horizontally */
  max-width: 1200px; /* Set a maximum width for the grid container */
  margin: 0 auto; 
}

/* Ensure the grid items are not stretched beyond their content */
.grid-item1, .grid-item2, .grid-item3, .grid-item4, .grid-item5 {
  background:white;
  overflow: hidden;
  text-align: center;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(14, 34, 64, 0.1);
  transition: background-color 0.3s ease;
  width: 90%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border-radius: 10px;
  
}

/* Image Styling */
.grid-item1 img, 
.grid-item2 img, 
.grid-item3 img, 
.grid-item4 img, 
.grid-item5 img {
  width: 60%; /* Make images fill the container */
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto 10px;
  transition: filter 0.3s ease;
}

.grid-item4 img{
  width: 48%;
  height: auto;
}

.grid-item5 img{
  width: 42%;
  height: auto;
}

/* Text Styling */
.grid-item1 h4, 
.grid-item2 h4, 
.grid-item3 h4, 
.grid-item4 h4, 
.grid-item5 h4 {
  font-size: 1.1em;
  color:rgba(14, 34, 64, 1);
  margin: 0;
  transition: color 0.3s ease;
}

/* Hover Effects */
.grid-item1:hover, 
.grid-item2:hover, 
.grid-item3:hover, 
.grid-item4:hover, 
.grid-item5:hover {
  background:rgba(14, 34, 64, 1);
  transform: translateY(-3px);
}

.grid-item1:hover img, 
.grid-item2:hover img, 
.grid-item3:hover img, 
.grid-item4:hover img, 
.grid-item5:hover img {
  filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%);
}

.grid-item1:hover h4, 
.grid-item2:hover h4, 
.grid-item3:hover h4, 
.grid-item4:hover h4, 
.grid-item5:hover h4 {
  color: white;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .grid-item1 img, 
  .grid-item2 img, 
  .grid-item3 img, 
  .grid-item4 img, 
  .grid-item5 img {
      width: 90%;
  }

  
}

@media (max-width: 992px) {
  .grid-item1 img, 
  .grid-item2 img, 
  .grid-item3 img, 
  .grid-item4 img, 
  .grid-item5 img {
      width: 80%;
  }
}

@media (max-width: 768px) {
  .grid-item1 img, 
  .grid-item2 img, 
  .grid-item3 img, 
  .grid-item4 img, 
  .grid-item5 img {
      width: 70%;
  }

  .grid-item1 h4, 
  .grid-item2 h4, 
  .grid-item3 h4, 
  .grid-item4 h4, 
  .grid-item5 h4 {
      font-size: 1em;
  }
}

@media (max-width: 480px) {
  .grid-item1 img, 
  .grid-item2 img, 
  .grid-item3 img, 
  .grid-item4 img, 
  .grid-item5 img {
      width: 50%;
  }

  .Customer .title{
    font-size: 1.2rem;
  }

  .Customer .subtitle{
    font-size: 0.9rem;
  }

  .grid-item1 h4, 
  .grid-item2 h4, 
  .grid-item3 h4, 
  .grid-item4 h4, 
  .grid-item5 h4 {
      font-size: 0.9em;
  }

  .grid-item1, .grid-item2, .grid-item3, .grid-item4, .grid-item5 {
      width: 80%; /* Full width for very small screens */
      margin: 0 auto;
  }
}

/* Customer section ends  */

/* Contact us section starts   */


.cont{
   background-color: rgba(14, 34, 64, 1);
    width: 100%;
    /* padding: 20px 0;  */
    border-bottom: 3px solid rgba(3, 214, 163, 1);

}
 
  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center;    /* Center horizontally */
    height:auto;          /* Full viewport height */
    background-color:rgba(14, 34, 64, 1); /* Background color */
    text-align: center;
    overflow: hidden; /* Prevents overflow */
    /* border-bottom: 2px solid rgba(3, 214, 163, 1); */
    max-width: 1520px;
    margin: 0 auto;
  }
  
  .header {
    margin-bottom: 20px; /* Space between header and form */
  }
  
  .header h1 {
    margin-top: 50px;
    color: white;
  }
  
  .subheading {
    margin: 0;
    color:white;
    font-size: 24px; /* Smaller font size for subheading */
    font-weight: 550;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure the form width does not exceed container width */
    width: 35%;
    /* background:#f8f8f8; Form background color */
    padding: 20px;
    /* border-radius: 0px; Rounded corners for the form */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); Shadow for the form */
    /* box-sizing: border-box; Includes padding and border in element's total width and height */
    overflow: hidden; /* Prevents overflow within the form */
    border-radius: 3px;
  }
  
  .contact-form label {
    align-self: flex-start; /* Align labels to the left */
    margin-top: 10px;
    color: #333;
  }
  
  .contact-form input, 
  .contact-form select {
    width: 90%; /* Full width of the form */
    padding: 10px;
    margin: 0px 0 30px 0; /* Space between fields */
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    box-sizing: border-box; /* Includes padding and border in element's total width and height */
    height: auto; 
    background-color:rgba(250, 249, 254, 1);
    height: 45px;
    align-items: center;
    
  }

  .contact-form ::placeholder{
    font-size:15px;
    color: black;
    font-weight: 600;
    
  }
  
  .contact-form button {
    padding: 10px 20px;
    border: none;
    background-color:rgba(3, 214, 163, 1);
    color: white;
    font-size: 22px;
    border-radius: 4px;
    width: 90%;
    height: 55px;
    cursor: pointer;
    box-sizing: border-box; /* Includes padding and border in element's total width and height */
    font-weight: 600;
  }
  
  .contact-form button:hover {
    /* background-color: #070708;  */
    transform: translateY(-3px);
  }

  .success-messagee{
    color:white;
    font-weight: 600;
    
  }
  
  .error-messagee{
    color:white;
    font-weight: 600;
  }

  .additional-info {
    margin-top: 20px;
    max-width: 90%; /* Ensure additional info width does not exceed container width */
    width: 35%;
    text-align: left;
    padding: 0 10px; /* Add some padding to prevent content from touching edges */
    box-sizing: border-box; /* Includes padding in element's total width */
  }
  
  .additional-info label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .additional-info input[type="checkbox"] {
    width: 15px;  /* Adjust the width */
    height: 15px; /* Adjust the height */
    transform: scale(1.5); /* Scale the checkbox */
    -webkit-transform: scale(1.5); /* For Safari */
    margin-right: 15px; /* Adjust the spacing around the checkbox if needed */
    border-color:white;
   
    
  }
  
  .additional-info .check{
    font-weight: bold;
    /* text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    font-size: 14px;
    
  }
  
  .info-text {
    margin-top: 10px;
    color:white;
    font-size: 14px;
    /* font-weight: bold; */
    /* text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    
  }
  
  
  /* Tablet styles */
  @media (max-width: 992px) {
    .contact-form,
    .additional-info {
      width: 75%; /* Adjust the width to 50% on tablets */
    }
  
    
  }
  
  /* Small screen styles (mobile) */
  @media (max-width: 768px) {
    .contact-form,
    .additional-info {
      width: 75%; /* Adjust the width to 75% on small screens */
    }
  
    .info-text {
      font-size: 18px; /* Smaller font size on small screens */
    }

    .subheading{
      font-size: 22px;

    } 

    .additional-info .check{
      font-size: 18px;
    }

    .additional-info input[type="checkbox"]{
      width: 25px;
      height: 25px;
    }

  }
  
  /* Very small screens (mobile) */
  @media (max-width: 576px) {
    .contact-form,
    .additional-info {
      width: 90%; /* Adjust the width to 90% on very small screens */
    }

    .header h1{
      font-size: 24px;

    }

    .subheading{
      font-size: 18px;

    }      


    .contact-form button {
      font-size: 22px; /* Adjust button font size on small screens */
    }

    
    .additional-info input[type="checkbox"]{
      width: 20px;
      height: 20px;
    }

    .additional-info .check{
      font-size: 12px;
    }

    .info-text {
      font-size: 12px; /* Smaller font size on small screens */
    }
  }
  
  /* Contact us section ends  */
  
  /* Footer section starts */
  .footer {
    background-color:rgba(14, 34, 64, 1);
    color: white;
    padding: 40px 20px;
    font-family: 'Montserrat';

  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1520px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    min-width: 220px;
    margin: 10px;
  }
  
  .footer h3 {
    margin: 0;
  }
  
  .footer .game{
    margin: 0;
    font-size: large;
  }
  
  .footer p {
    font-size: 14px;
    line-height: 1.6;
    color: #cdcaca;
    padding: 7px;
    font-weight: 600;
  }
  
  .footer .powered-by {
    margin: 0;
    color:rgba(3, 214, 163, 1);
    font-size:small;
    font-weight: 600;
  }
  
  .footer ul {
    list-style: none;
    padding: 5px;
    text-align: left;
    
  }
  
  .footer li {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px;
    color: #cdcaca;
    font-weight: 600;
    
  }

  a:hover {
    text-decoration-line: none;
    transform: translateY(-3px); /* Move the text up slightly */
    
  }

  
  
  .footer .Us{
    margin-top: 50px;
    font-size: medium;
  }
  
  .footer .num {
    margin-top: 10px;
    color: #f5f5f5;
    margin-bottom: 0px;
  
  }
  
  .footer .phone{
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
  
  .footer .mail{
    width: 20px;
    height: auto;
    margin-right: 10px;
  
  }

  .footer a li:hover{
    transform: translateY(-3px);
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    margin-top: 0px;
  
  }

  .sales{
    display: inline-block;
  }
  
  .footer a:hover {
    text-decoration: none;
    transform: translateY(-3px);
  }
  
  .social-icons a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons img{
    display: flex;
    justify-content: center;
    width: 20px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%)  
  }

  

  
  /* Responsive Design */
  
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
    }
  
    .footer-column {
      margin: 20px 0;
    }
  
    .social-icons i {
      font-size: 24px;
    }
  }
  
/* Footer section ends  */