body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;  
  }


/* Navbar styling */

/* Navbar section styling starts */
.nav{
  width: 100%;
  background-color:rgba(14, 34, 64, 1);
  box-sizing: border-box;
  border-bottom: 3px solid rgba(3, 214, 163, 1);
}


.navbar {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 7.5rem;
  background-color: rgba(14, 34, 64, 1);
  color: black;
  position: relative;
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
}

.navbar {
  background-color: rgba(14, 34, 64, 1);
  width: 100%;
  position: relative;
  box-sizing: border-box;
  
}

/* Content wrapper to center the navbar content */
.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px; /* Centering limit for large screens */
  margin: 0 auto; /* Center the content */
  width: 100%;
  
}

/* Styling for left section of the navbar */
.navbar-left h1 {
  margin: 0;
  font-size: 1.5rem;
  color: white;
}

.powered-by {
  font-size: 0.9rem;
  color: rgba(3, 214, 163, 1);
  font-weight: 600;
  margin: 0;
}

/* Styling for right section of the navbar */
.navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  
}

/* Styling for dropdown container */
.dropdown {
  /* position: relative; */
  margin-right: 1rem;
}

/* Styling for dropdown toggle button */
.dropdown-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  /* position: relative; */
}

/* Triangle for dropdown toggle */
.triangle {
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s ease;
  color: rgba(3, 214, 163, 1);
}



.rotate {
  transform: rotate(180deg); /* Rotate the triangle 180 degrees */
}

/* Dropdown menu styles for large screens */
.dropdown-menu {
  position: absolute;
  background-color: rgba(239, 239, 239, 0.8);
  padding:1rem 1rem;
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(4, 1fr); /* Four equal-width columns */
  gap: 1rem; /* Space between items */
  width: 100%; /* Full viewport width */
  left: 0; /* Align to the left edge of the viewport */
  top: 78px; /* Position just below the navbar */
  box-sizing: border-box; /* Include padding in width calculation */
  z-index: 9998; /* Ensure it is above other content */
}

/* Dropdown items styling */
.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: left; /* Align content to the left */
  text-decoration: none;
  color: rgba(14, 34, 64, 1);
  padding: 1rem; /* Adjust padding as needed */
  background-color: white;
  text-align: center;
  box-sizing: border-box; /* Include padding in width calculation */
  height: auto;
  overflow: hidden; /* Hide overflow to maintain size */
  border-radius: 10px;
}

/* Ensure images and text fit within item size */
.dropdown-item img {
  width: 40px; /* Adjust to fit the item size */
  height: auto; /* Maintain aspect ratio */
  margin-right: 0.5rem; /* Space between image and text */
  filter: brightness(0) saturate(100%) invert(17%) sepia(34%) saturate(623%) hue-rotate(175deg) brightness(100%) contrast(101%);

}

.dropdown-item span {
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
}

.build-park-btn {
  padding: 0.5rem 1rem;
  background-color:rgba(14, 34, 64, 1);
  border:2px solid rgba(3, 214, 163, 1);
  cursor: pointer;
  color:rgba(239, 239, 239, 1);
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;

}

/* Hamburger menu for small screens */
.hamburger {
  display: none;
  background:none;
  color: white;
  font-size: 2.5rem;
  cursor: pointer;
  font-weight: bolder;
  border: none;

}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-color:rgba(239, 239, 239, 1);
  z-index: 9999; /* Ensure it is above everything else */
  display: flex;
  flex-direction: column;
  overflow:scroll; /* Allow scrolling for hamburger menu content */
  align-items: center; /* Center items horizontally */
  padding-left: 15px; 
}

.close-btn {
  background: none;
  border: none;
  color:rgba(14, 34, 64, 1);
  font-size: 3.5rem; /* Increased size */
  cursor: pointer;
  align-self: flex-end; /* Ensures it aligns to the top-right */
  margin-right: 2.5rem; /* Add some margin to move it away from the right edge */
  margin-top: 0rem; /* Add margin from the top */
  margin-bottom: 2rem;
  z-index: 10000; /* Ensure it stays on top */
}

.hamburger-dropdowns {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%; /* Full width of the screen */
  gap: 10px; 
}

/* Ensure dropdown menu styles for small screens are centered */
.hamburger-dropdowns .dropdown-menu.full-width {
  flex-direction: column; /* Stack items vertically */
  width: 100%; /* Full width of the screen */
  height: auto;
  text-align: center;
  position: static; /* Ensure static position for small screens */
  z-index: 9997; /* Ensure it is below the close button but above the overlay */
}

/* Responsive Design */

@media (max-width: 922px) {
  .navbar{
    padding: 5px 5px;
  }

  .navbar-left h1 {
    margin: 0;
    font-size: 1.3rem;
    color: white;

  }

  
  
  .powered-by {
    font-size: 0.8rem;
    color: rgba(3, 214, 163, 1);
    font-weight: 600;
    margin: 0;
  
  }

  .navbar-right {
    display: none;
  }

  .dropdown-toggle{
    color:rgba(14, 34, 64, 1);
    font-size: 1.3rem;
    font-weight: bold;
    /* width: 100px; */
    justify-items: auto;
    padding: 10px;
  }

  .hamburger {
    display: block;
  }

  /* Ensure dropdown menu styles for small screens are vertically aligned and centered */
  .dropdown-menu {
    width: 100%;
    padding-right: 30px;
    display: flex; /* Flex layout for small screens */
    flex-direction: column; /* Stack items vertically */
    position: absolute;
    top: 40px; /* Adjust top position to be below the navbar */
    left: 0; /* Align to the left edge */
    right:0; /* Align to the right edge */
    /* margin: 0 auto; */
    box-sizing: border-box; /* Include padding in width calculation */
    overflow-y: auto; /* Allow vertical scrolling if needed */
    max-height: calc(100vh); 
    z-index: 9997; /* Ensure it is below the overlay but above other content */
    border-right: 10px;
  }

  .dropdown-item {
    /* flex: 1 0 auto;  */
    display: block; /* Ensure items stack vertically */
    display: flex;
    justify-content:left; 
  }

  .dropdown-item img{
    width: 50px;
    height: auto;
    padding-right: 10px; 
  }

  .build-park-btn {
    width: 200px;
    height: 50px;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }

  .social-icons1 a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons1 img{
    display: flex;
    justify-content: center;
    width: 30px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%);  
  }

  
}

/* navbar section ends  */

/* fitness1 section starts  */
.fitness1 {
  width: 100%;
  height: auto;
  
}

.fitnessintro {
  max-width: 1520px;             /* Keep the max width */
  width: 100%;                   /* Full width */
  height: 500px;                 /* Fixed height */
  background: url('../Assests/fitpic1.png') no-repeat center center/cover; /* Cover background */
  background-size: 100% 100%;
  margin: 0 auto;                /* Center horizontally */
  display: flex;                 /* Flexbox for layout */
  flex-direction: column;        /* Column layout */
  align-items: center;           /* Center content horizontally */
  justify-content: center;       /* Center content vertically */
  padding: 20px 0px;                 /* Padding for spacing */
}

.fitnesshead1 {
  background-color: rgba(14, 34, 64, 1);
  border-radius: 10px;
  padding: 20px;                 /* Add padding for better spacing */
  width: 60%;
  text-align: center;
}

.fitnesshead1 h3 {
  margin: 0;
  font-size: 24px;               /* Default font size */
  padding: 10px 20px;           /* Padding for the heading */
  color: rgba(3, 214, 163, 1);   /* Text color */
}

.fitnesshead1 h5 {
  margin: 0;
  font-weight: 500;              /* Font weight */
  padding-bottom: 10px;          /* Bottom padding */
  color: white;                  /* Text color */
  text-align: center;            /* Center text */
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .fitnessintro {
      height: 400px;             /* Reduce height on smaller screens */
  }

  .fitnesshead1{
    width: 80%;
  }

  .fitnesshead1 h3 {
      font-size: 24px;           /* Reduce font size on smaller screens */
  }

  .fitnesshead1 h5 {
      font-size: 18px;           /* Adjust font size for better readability */
  }
}

@media (max-width: 768px) {
  .fitnessintro {
      height: 300px;             /* Further reduce height on mobile */
      padding: 10px 0px;             /* Reduce padding */
  }

  .fitnesshead1 h3 {
      font-size: 20px;           /* Further reduce font size */
  }

  .fitnesshead1 h5 {
      font-size: 14px;           /* Adjust font size for mobile */
  }
}

@media (max-width: 480px) {
  .fitnessintro {
      height: 300px; /* Further reduce height on mobile */         
  }

  .fitnesshead1{
    width: 80%;
    
  }

  .fitnesshead1 h3 {
      font-size: 16px;           /* Further reduce font size */

  }

  .fitnesshead1 h5 {
      font-size: 12px;           /* Adjust font size for mobile */
  }
}

/* fitness1 section ends  */

/* fitness2 section starts  */
.fitness2 {
  width: 100%;
  height: auto;
  
}

.fitnessintro1 {
  display: flex;
  flex-wrap: wrap;                 /* Allow items to wrap */
  align-items: center;             /* Center items vertically */
  justify-content: center;         /* Center items horizontally */
  max-width: 1520px;              /* Maximum width */
  width: 100%;                     /* Full width */
  margin: 0 auto;                 /* Centered horizontally */
  background-color: white;         /* Background color */
  
}

.fitnesshead2 {
  display: flex;                   /* Flex container */
  flex-wrap: wrap;                /* Allow wrapping */
  align-items: center;             /* Center items vertically */
  justify-content: center;         /* Center items horizontally */
  padding: 10px 5%;               /* Responsive padding */
}

.fitnesshead2 h3 {
  font-size: 34px;                /* Default font size */
  color: rgba(14, 34, 64, 1);     /* Text color */
  margin-bottom: 5px;             /* Bottom margin */
}

.fitnesshead2line {
  border: none;                    /* No border */
  border-top: 4.5px solid rgba(3, 214, 163, 1); /* Top border */
  margin: 10px 0;                 /* Vertical margin */
  width: 100%;                    /* Full width on smaller screens */
  max-width: 600px;               /* Max width for larger screens */
}

.fitnesshead2 h5 {
  margin: 10px;                   /* Margin for spacing */
  font-weight: 600;               /* Font weight */
  font-size: 18px;                /* Default font size */
  color: rgba(14, 34, 64, 1);
}

.fitnesscontent1 {
  display: flex;                  /* Flex container */
  flex-wrap: wrap;                /* Allow wrapping */
  align-items: center;            /* Center items vertically */
  justify-content: center;        /* Center items horizontally */
  gap: 100px;                      /* Gap between items */
  padding: 20px;                  /* Padding around content */
}

.fitnesscontent1 img {
  width: 100%;                    /* Full width for responsiveness */
  max-width: 600px;               /* Max width for images */
  height: auto;                   /* Maintain aspect ratio */
  border-radius: 8px;
}

.fitnesscontent1 h3 {
  font-size: 24px;                /* Default font size */
  text-align: center;              /* Center text */
  color: rgba(14, 34, 64, 1);
}

.fitnesscontent1 h5 {
  font-size: 18px;                /* Default font size */
  text-align: center;              /* Center text */
  font-weight: 550;               /* Font weight */
  color: rgba(14, 34, 64, 1);
}

.fitnesscontent1 button {
  font-size: 24px;                /* Font size */
  width: 100%;                    /* Full width for buttons */
  max-width: 250px;               /* Max width */
  border-radius: 5px;             /* Rounded corners */
  color: white;                   /* Text color */
  background-color: rgba(3, 214, 163, 1); /* Background color */
  display: block;                 /* Block display */
  margin: 0 auto;                /* Center horizontally */
  border: none;                   /* No border */
  padding: 10px 5px;
  font-weight: 550;
}

.fitnesscontent1 button:hover {
  transform: translateY(-3px);    /* Hover effect */
  cursor: pointer;                 /* Pointer cursor */
}

.fitnesscontent1 a {
  font-size: 22px;                /* Font size */
  text-align: center;              /* Center text */
  text-decoration: none;           /* No underline */
  color: #0057FF;                   /* Link color */
  display: block;                 /* Block display */
  align-items: center;            /* Center items */
  font-weight: 700;               /* Font weight */
  margin: 0 auto;                /* Center horizontally */
  cursor: pointer;
}

.fitnesscontent1 h6 {
  text-align: center;              /* Center text */
  font-size: 18px;                /* Font size */
  font-weight: 550;               /* Font weight */
  color: rgba(14, 34, 64, 1);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .fitnesshead2 h3 {
      font-size: 24px;            /* Adjust font size for smaller screens */
  }

  .fitnesshead2 h5 {
      font-size: 16px;            /* Adjust font size for smaller screens */
  }

  .fitnesscontent1 {
      gap: 10px;                  /* Reduce gap between items */
  }

  .fitnesscontent1 img {
      max-width: 550px;           /* Smaller max width for images */
  }

  .fitnesscontent1 button {
      max-width: 250px;           /* Smaller max width for buttons */
  }
}

@media (max-width: 768px) {
  .fitnesshead2 h3 {
      font-size: 24px;            /* Further reduce font size */
  }

  .fitnesshead2 h5 {
      font-size: 14px;            /* Further reduce font size */
  }

  .fitnesscontent1 {
      flex-direction: column;      /* Stack items vertically */
      align-items: center;         /* Center items */
  }

  .fitnesscontent1 img {
      max-width: 90%;            /* Full width images */
  }

  .fitnesscontent1 h3, .fitnesscontent1 h5, .fitnesscontent1 h6 {
      font-size: 16px;            /* Adjust font size for smaller screens */
  }

  .fitnesscontent1 button {
      max-width: 100%;            /* Full width for buttons */
  }
}

@media (max-width: 480px) {
  .fitnesshead2 h3 {
      font-size: 20px;            /* Further reduce font size */
      text-align: center;
  }

  .fitnesshead2 h5 {
      font-size: 14px;            /* Further reduce font size */
      text-align: center;
  }

  .fitnesscontent1 {
      flex-direction: column;      /* Stack items vertically */
      align-items: center;         /* Center items */
  }

  .fitnesscontent1 img {
      max-width: 90%;            /* Full width images */
  }

  .fitnesscontent1 h3, .fitnesscontent1 h5, .fitnesscontent1 h6 {
      font-size: 16px;            /* Adjust font size for smaller screens */
      text-align: center;
  }

  .fitnesscontent1 button {
      max-width: 100%;
      font-size: 16px;            /* Full width for buttons */
  }
}
/* fitness2 section ends  */

/* fitness3 section starts  */
.fitness3 {
  width: 100%;                     /* Full width */
  height: auto;                    /* Automatic height */
}

.fitnessintro2 {
  max-width: 1520px;               /* Maximum width */
  margin: 0 auto;                  /* Centered horizontally */
  padding: 20px 250px;                /* Responsive padding */
}

.fitnesshead3 h3 {
  text-align: center;
  margin-bottom: 20px;             /* Bottom margin */
  font-size: 34px;                 /* Font size */
  color:rgba(14, 34, 64, 1);                     /* Text color */
}

.fitnesscontent2 {
  display: flex;                   /* Flex container */
  flex-wrap: wrap;                /* Allows items to wrap to the next row */
  gap: 20px;                      /* Space between items */
}

.fitnesssubcontentitem1 {
  flex: 1 1 calc(50% - 20px);     /* Two items per row */
  box-sizing: border-box;         /* Include padding in width */
  background-color:white;        /* Background color */
  /* padding: 15px;   */
  border-radius: 8px;             /* Rounded corners */
  /* box-shadow: 0 4px 8px rgba(14, 34, 64, 0.1);  */
  text-align: left;               /* Align text to the left */
}

.fitnesssubcontentitem1:hover{
  transform: translateY(-3px);
}

.fitnesssubcontentitem1 img {
  max-width:100%;                /* Full width for images */
  height: auto;                   /* Maintain aspect ratio */
  border-radius: 8px;             /* Rounded image corners */
  margin-bottom: 15px;            /* Spacing below image */
  display: block;                 /* Center images */
  margin-left: auto;              /* Center images horizontally */
  margin-right: auto;             /* Center images horizontally */
}

.fitnesssubcontentitem1 h4 {
  font-size: 18px;                /* Font size */
  color:rgba(14, 34, 64, 1);
  margin-bottom: 10px;                    /* Text color */
             
}

.fitnesssubcontentitem1 h6 {
  font-size: 14px;                /* Font size */
  color:rgba(14, 34, 64, 1);                    /* Text color */
  line-height: 1.5;               /* Line height */
  margin: 0;
  font-weight: 500;
}

/* Responsive Design */

@media (max-width: 1200px) {
  .fitnessintro2 {
    padding: 20px 60px;           /* Adjust padding for smaller screens */
  }

  .fitnesshead3 h3 {
    font-size: 24px;              /* Smaller font size for headings */
  }

  .fitnesssubcontentitem1 {
    flex: 1 1 calc(50% - 20px);               /* Full width for items */
  }

  .fitnesssubcontentitem1 h4{
    font-size:18px ;
  }
  .fitnesssubcontentitem1 h6 {
    font-size: 16px;              /* Adjust font sizes for smaller screens */
  }

  .fitnesssubcontentitem1 img {
    max-width: 100%;               /* Image width adjustment */
  }
}



@media (max-width: 768px) {
  .fitnessintro2 {
    padding: 20px 60px;           /* Adjust padding for smaller screens */
  }

  .fitnesshead3 h3 {
    font-size: 24px;              /* Smaller font size for headings */
  }

  .fitnesssubcontentitem1 {
    flex: 1 1 calc(50% - 10px);               /* Full width for items */
    max-width: 50%;
  }

  .fitnesssubcontentitem1 h4,
  .fitnesssubcontentitem1 h6 {
    font-size: 16px;              /* Adjust font sizes for smaller screens */
  }

  .fitnesssubcontentitem1 img {
    max-width: 100%;               /* Image width adjustment */
  }
}

@media (max-width: 480px) {
  .fitnessintro2{
    padding: 0px 30px;
    padding-bottom: 20px;
  
  }

  .fitnesshead3 h3{
    font-size: 24px;
    text-align:center;
  }

  .fitnesscontent2{
    display: block;

  }

  .fitnesssubcontentitem1{
    flex: 1 1 100%;
    margin: 0 auto;
    max-width: 100%;
  }

  .fitnesssubcontentitem1 h4{
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
    
  }

  .fitnesssubcontentitem1 h6{
    font-size: 14px;
    margin-bottom: 25px;
    text-align: center;
  }

  .fitnesssubcontentitem1 img{
    margin-bottom: 0;
  }

}
/* fitness3 section ends  */

/* fitness4 section starts  */
.fitness4 {
  width: 100%;
  background-color:white;  /* Background color for the section */
}

.fitnessintro3 {
  max-width: 1520px;
  margin: 0 auto;              /* Centering the intro section */
  padding: 20px 100px;            /* Responsive padding for smaller screens */
}

.fitnesshead4 h3 {
  text-align:left;          /* Centered heading */
  margin-bottom: 10px;         /* Space below heading */
  font-size: 34px;             /* Font size */
  color:rgba(14, 34, 64, 1);                 /* Text color */
  margin: 0;
}

.fitnesshead4 h5 {
  text-align:left;          /* Centered subheading */
  font-size: 16px;             /* Font size */
  color:rgba(14, 34, 64, 1);                 /* Text color */
  margin-bottom: 20px;  
  font-weight: 500;       /* Space below subheading */
  margin-top: 10px;
}

.fitnesscontent3 {
  display: flex;               /* Flex container */
  justify-content: space-between; /* Even spacing between items */
  flex-wrap: wrap;             /* Allows items to wrap on smaller screens */
  gap: 20px;                   /* Adds spacing between items */
}

.fitsubcontentitem2 {
  flex: 1 1 calc(33.333% - 20px); /* Each item takes one-third of the row, minus gap */
  box-sizing: border-box;       /* Include padding in width */
  text-align:center;           /* Centered text */
  /* padding: 15px;                */
  background-color:white;       /* Background color for items */
  border-radius: 8px;           /* Rounded corners */
  /* box-shadow: 0 4px 8px rgba(14, 34, 64, 0.1); */
  height:auto;                /* Fixed height for consistency */
  
}

.fitsubcontentitem2:hover{
  transform: translateY(-3px);
}

.fitsubcontentitem2 img {
  height: auto;                 /* Maintain aspect ratio */
  margin-bottom:10px;          /* Space between image and text */
  border-radius: 8px;           /* Optional: Rounded corners for images */
  margin: 0;
  max-width: 100%;
}

.fitsubcontentitem2 h5 {
  font-size: 16px;              /* Font size for item titles */
  color:rgba(14, 34, 64, 1);                  /* Text color */
  margin-bottom: 10px;
  margin-top: 5px;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .fitnessintro3{
    padding: 20px 60px;
  }
  .fitsubcontentitem2 {
    flex: 1 1 calc(50% - 20px); /* Two items per row on medium screens */
    max-width: 50%;
  }

  .fitnesshead4 h3 {
    font-size: 24px;            /* Smaller heading on medium screens */
  }

  .fitnesshead4 h5 {
    font-size: 16px;            /* Smaller subheading on medium screens */
  }

  .fitsubcontentitem2 img{
    max-width: 100%;
  }
}

@media (max-width: 768px) {

  .fitnessintro3{
    padding: 20px 60px;
  }

  .fitnesscontent3 {
    gap: 20px;                  /* Reduce gap for smaller screens */
  }

  .fitsubcontentitem2 {
    flex: 1 1 calc(50% - 20px); /* Full width items on small screens */
    height: auto;               /* Allow height to adjust based on content */
    max-width: 50%;
  }

  .fitsubcontentitem2 img {
    max-width: 100%;             /* Adjust image size for small screens */
  }

  .fitnesshead4 h3 {
    font-size: 24px;            /* Even smaller heading on small screens */
  }

  .fitnesshead4 h5 {
    font-size: 18px;            /* Even smaller subheading on small screens */
  }
}

@media (max-width: 480px) {
  .fitnessintro3 {
    max-width: 100%;
    padding: 20px 30px;   
  }

  .fitnesscontent3{
    display: block;

  }

  .fitsubcontentitem2 {
    flex: 1 1 calc(100% - 10px); /* Full width items on small screens */
    height: auto;               /* Allow height to adjust based on content */
    margin: 0 auto;
    max-width: 100%;
  
  }

  .fitsubcontentitem2 img {
    max-width: 100%;             /* Adjust image size for small screens */
  }

  .fitnesshead4 h3 {
    font-size: 20px;            /* Even smaller heading on small screens */
    text-align: center;
  }

  .fitnesshead4 h5 {
    font-size: 14px;            /* Even smaller subheading on small screens */
    text-align: center;
  }

  .fitsubcontentitem2 h5{
    margin-bottom: 25px;
    text-align: center;
  }
}
/* fitness4 section ends  */


/* fitness5 section starts  */
.fitness5 {
  width: 100%;
  background-color:white;  /* Optional: Background color for the section */
}

.fitnessintro4 {
  max-width: 1520px;
  margin: 0 auto;
  padding: 0px 100px;
}

.fitnesshead5 h3 {
  text-align:left;
  margin-bottom: 10px;
  font-size: 26px;
  color:rgba(14, 34, 64, 1);
  margin: 0;
}

.fitnesshead5 h5 {
  text-align:left;
  font-size: 16px;
  color:rgba(14, 34, 64, 1);
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 30px;
}

.fitnesscontent4 {
  display: flex;
  justify-content: space-between;  /* Ensures even spacing between the items */
  flex-wrap: wrap;                 /* Allows items to wrap if needed */
  gap: 20px;                       /* Adds spacing between items */
}

.fitsubcontentitem3 {
  flex: 1 1 calc(10% - 10px);      /* Each item takes 20% of the row, minus gap */
  box-sizing: border-box;          /* Ensures padding and borders are included */
  text-align: center;              /* Centers the content */
  /* padding: 10px; */
  background-color:white;          /* Optional: Background for individual items */
  border-radius: 8px;              /* Optional: Rounded corners */
  /* box-shadow: 0 4px 8px rgba(14, 34, 64, 0.1);  */
}

.fitsubcontentitem3:hover{
  transform: translateY(-3px);
}

.fitsubcontentitem3 img {
  max-width: 100%;                 /* Ensures image fits within the container */
  height: auto;
  margin-bottom: 10px;             /* Space between image and text */
  border-radius: 8px;              /* Optional: Rounded corners for images */
}

.fitsubcontentitem3 h5 {
  font-size: 16px;
  color:rgba(14, 34, 64, 1);
  margin-top:5px;
}

/* Responsive Design for Smaller Screens */

@media (max-width: 1200px) {

  .fitnessintro4{
    padding: 20px 60px;
  }

  .fitnesscontent4{
    gap: 20px;
  }


  .fitsubcontentitem3 {
    flex:1 1 calc(50% - 20px);                /* Stacks items vertically on smaller screens */
    max-width: 50%;
  }

  .fitnesshead5 h3 {
    font-size: 24px;
  }

  .fitnesshead5 h5 {
    font-size: 16px;
  }
}


@media (max-width: 768px) {
  .fitnessintro4{
    padding: 20px 60px;
    
  }

  .fitnesscontent4{
    gap: 20px;
  }


  .fitsubcontentitem3 {
    flex:1 1 calc(50% - 10px);        /* Stacks items vertically on smaller screens */
    max-width: 50%; 
  }

  .fitnesshead5 h3 {
    font-size: 24px;
  }

  .fitsubcontentitem3 img{
    max-width: 100%;
  }

  .fitnesshead5 h5 {
    font-size: 18px;
  }
}

@media (max-width:480px) {
  .fitnessintro4{
    padding: 30px 40px;
  }


  .fitsubcontentitem3 {
    flex:1 1 calc(100% - 10px);                /* Stacks items vertically on smaller screens */
    max-width: 100%;
  }

  .fitnesshead5 h3 {
    font-size: 20px;
    text-align: center;
  }

  .fitnesshead5 h5 {
    font-size: 16px;
    text-align: center;
  }

  .fitsubcontentitem3 h5{
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-align: center;
  }

  .fitsubcontentitem3 img{
    margin-bottom: 0px;
  }
}
/* fitness5 section ends  */

/* fitness6 section starts  */
.fitness6 {
  width: 100%;
  background-color:white;  /* Optional: Background color for the section */
}

.fitnessintro5 {
  max-width: 1520px;          /* Updated max-width */
  margin: 0 auto;
  padding: 0px 100px;
}

.fitnesshead6 h3 {
  text-align:left;
  margin-bottom: 30px;
  font-size: 28px;
  color:rgba(14, 34, 64, 1);
}

.fitnesscontent5 {
  display: flex;
  justify-content: space-between;  /* Ensures even spacing between items */
  flex-wrap: wrap;                 /* Prevents wrapping, keeps items in a row */
  gap: 20px;                       /* Adds spacing between items */
}

.fitsubcontentitem4 {
  flex: 1 1 calc(25% - 20px);      /* Each item takes 25% of the row, minus gap */
  box-sizing: border-box;          /* Ensures padding and borders are included */
  text-align: center;
  /* padding: 10px; */
  background-color:white;          /* Optional: Background for individual items */
  border-radius: 8px;              /* Optional: Rounded corners */
  /* box-shadow: 0 4px 8px rgba(14, 34, 64, 0.1);  */
}

.fitsubcontentitem4:hover{
  transform: translateY(-3px);
}

.fitsubcontentitem4 img {
  max-width:100%;                 /* Ensures image fits within the container */
  height: auto;
  margin-bottom: 10px;             /* Space between image and text */
  border-radius: 8px;              /* Optional: Rounded corners for images */
}

.fitsubcontentitem4 h5 {
  font-size: 18px;
  color:rgba(14, 34, 64, 1);
  margin-top:5px;
}

/* Responsive Design for Smaller Screens */

@media (max-width: 1200px) {
  .fitnessintro5{
    padding: 30px 60px;
  }


  .fitsubcontentitem4 {
    flex:1 1 calc(50% - 20px);                /* Stacks items vertically on smaller screens */
    max-width: 50%;
 
  }

  .fitnesshead6 h3 {
    font-size: 24px;
  }

  .fitnesshead6 h5 {
    font-size: 16px;
  }
}


@media (max-width: 768px) {

  .fitnessintro5{
    padding: 30px 60px;
  }


  .fitsubcontentitem4 {
    flex: 1 1 calc(50% - 20px); 
    max-width: 50%;               /* Stacks items vertically on smaller screens */
  }

  .fitnesshead6 h3 {
    font-size: 22px;
  }

  .fitnesshead6 h5 {
    font-size: 16px;
  }
}


@media (max-width: 480px) {

  .fitnessintro5{
    padding: 30px 40px;
  }


  .fitsubcontentitem4 {
    flex: 1 1 calc(100% - 20px); 
    max-width: 100%;               /* Stacks items vertically on smaller screens */
  }

  .fitnesshead6 h3 {
    font-size: 20px;
    text-align: center;
  }

  .fitnesshead6 h5 {
    font-size: 16px;
    text-align: center;
  }

  .fitsubcontentitem4 img{
    max-width: 100%;
    margin-bottom: 0px;
  }

  .fitsubcontentitem4 h5{
    margin-top: 0px;
    margin-bottom: 5px;
    text-align: center;
  }
}
/* fitness6 section ends  */

/* fitness7 section starts  */
.fitness7 {
  max-width:100%;
  height: auto;
}

.fitnessintro6 {
  text-align: center;               /* Center text for the intro */
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0px;
}

.fitnesshead7 h3 {
  font-size: 28px;
  margin-bottom: 10px;
  color: rgba(14, 34, 64, 1);
  text-align: left;
  padding: 0px 100px;
  margin: 0;
}

.fitnesshead7 h6 {
  font-size: 18px;
  color:rgba(14, 34, 64, 1);
  margin-bottom: 20px;
  padding: 10px 100px;
  text-align: left;
  font-weight: 500;
  margin: 0;
}

.fitnesssubhead7 {
  max-width: 300px;                 /* Set a maximum width */
  width: 100%;
  margin: 0 auto;                   /* Center the subhead */
  background-color:rgba(14, 34, 64, 1);           /* Dark background */
  color:white;                      /* White text */
  padding: 30px;                    /* Padding inside the subhead */
  border-radius: 10px;              /* Rounded corners */
  text-align: center;               /* Center the text */
  margin-top: 20px;                 /* Space above */
}

.fitnesssubhead7 h3 {
  font-size: 24px;
  margin-bottom: 10px;
  
}

.fitnesssubhead7 h5 {
  font-size: 18px;
  margin: 5px 0;
  font-weight: 500;
}

.fitnesssubhead7 button {
  background-color:rgba(3, 214, 163, 1);        /* Button color */
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 22px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease; /* Smooth hover effect */
  font-weight: 550;
}

.fitnesssubhead7 button:hover {
  transform: translateY(-3px);
}

/* Responsive Design */

@media (max-width: 1200px) {

  .fitnesshead7{
    max-width: 1200px;
    width: 100%;
  }

  .fitnesshead7 h3{
    padding: 5px 60px;
  }

  .fitnesshead7 h6{
    padding: 0px 60px;
  }

  .fitnesssubhead7 {
    max-width:350px;
    padding: 20px;
  }

  .fitnesssubhead7 h3 {
    font-size: 24px;
  }

  .fitnesssubhead7 h5 {
    font-size: 16px;
  }

  .fitnesssubhead7 button {
    font-size: 18px;
    padding: 10px 20px;
  }
}


@media (max-width: 768px) {

  
  .fitnesshead7{
    max-width: 1200px;
    width: 100%;
  }

  .fitnesshead7 h3{
    padding: 5px 60px;
  }

  .fitnesshead7 h6{
    padding: 0px 60px;
  }


  .fitnesssubhead7{
    padding: 30px 100px;
  }

  .fitnesssubhead7 {
    max-width: 40%;
    padding: 20px;
  }

  .fitnesssubhead7 h3 {
    font-size: 24px;
  }

  .fitnesssubhead7 h5 {
    font-size: 16px;
  }

  .fitnesssubhead7 button {
    font-size: 18px;
    padding: 10px 20px;
  }
}


@media (max-width: 480px) {
   
  .fitnesshead7{
    max-width: 1200px;
    width: 100%;
  }

  .fitnesshead7 h3{
    font-size: 18px;
    padding: 0px 40px;
    text-align: center;
  }

  .fitnesshead7 h6{
    font-size: 14px;
    padding: 0px 40px;
    text-align: center;
  }


  .fitnesssubhead7{
    padding: 30px 40px;
  } 

  .fitnesssubhead7 {
    max-width: 70%;
    padding: 20px;
  }

  .fitnesssubhead7 h3 {
    font-size: 24px;
    text-align: center;
  }

  .fitnesssubhead7 h5 {
    font-size: 16px;
    text-align: center;
  }

  .fitnesssubhead7 button {
    font-size: 18px;
    padding: 10px 20px;
  }
}
/* fitness7 section ends  */

/* fitness faq section starts  */
.fitnessfaq-wrapper {
  width: 100%; /* Full width of viewport */
  background-color: #0E2240; /* Background color for the full-width section */
  border-bottom: 3px solid #03D6A3;
}

.fitnessfaq-container {
  max-width: 1600px; /* Max width for the content */
  margin: 0 auto; /* Centering the content */
  padding: 40px 100px; /* Padding inside the content area */
  background-color:#0E2240; /* Background color for the content area */
  text-align: center;
}

/* Base Styles for FAQ Section */
.fitnessfaq-container h1 {
  font-size: 32px; /* Adjust size as needed */
  margin-bottom: 20px; /* Space below the heading */
  color: white;
}

.fitnessfaq-item {
  margin: 10px 0; /* Space between FAQ items */
  /* border: 1px solid #ddd;  */
  border-radius: 0px; /* Rounded corners */
  overflow: hidden; /* Ensures content stays within borders */
  padding: 10px 0px;

}

.fitnessfaq-item:hover{
  transform: translateY(-3px);
}

.fitnessfaq-question {
  padding: 15px 75px; /* Padding inside the question section */
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  background-color:#445979dd; /* Background color for the question */
  display: flex;
  justify-content: space-between;
  align-items:center;
  color: white;

}

.fitnessfaq-question.active {
  background-color:#445979dd;; /* Background color for active question */
  color: #03D6A3;
}

.fitnessarrow {
  font-size: 20px; /* Size of the arrow icon */
}

.fitnessfaq-answer {
  padding: 15px; /* Padding inside the answer section */
  font-size: 24px; /* Adjust size as needed */
  background-color: #445979dd;; /* Background color for the answer */
  color: white;
}

/* Responsive Styles */
@media (max-width:1200px) {
  .fitnessfaq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .fitnessfaq-container h1 {
    font-size: 26px; /* Adjust font size for smaller screens */
  }

  .fitnessfaq-question {
    font-size: 20px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
  }

  .fitnessarrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .fitnessfaq-answer {
    font-size: 20px; /* Adjust font size for smaller screens */
  }
}



@media (max-width: 768px) {
  .fitnessfaq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .fitnessfaq-container h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .fitnessfaq-question {
    font-size: 14px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
  }

  .fitnessarrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .fitnessfaq-answer {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}


@media (max-width: 480px) {
  .fitnessfaq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .fitnessfaq-container h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .fitnessfaq-question {
    font-size: 14px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
    text-align: left;
  }

  .fitnessarrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .fitnessfaq-answer {
    font-size: 14px; /* Adjust font size for smaller screens */
    text-align: left;
  }
}

/* fitness faq section ends  */

  /* Footer section starts */
  .footer {
    background-color:rgba(14, 34, 64, 1);
    color: white;
    padding: 40px 20px;
    font-family: 'Montserrat';

  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1520px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    min-width: 220px;
    margin: 10px;
  }
  
  .footer h3 {
    margin: 0;
  }
  
  .footer .game{
    margin: 0;
    font-size: large;
  }
  
  .footer p {
    font-size: 14px;
    line-height: 1.6;
    color: #cdcaca;
    padding: 7px;
    font-weight: 600;
  }
  
  .footer .powered-by {
    margin: 0;
    color:rgba(3, 214, 163, 1);
    font-size:small;
    font-weight: 600;
  }
  
  .footer ul {
    list-style: none;
    padding: 5px;
    text-align: left;
    
  }
  
  .footer li {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px;
    color: #cdcaca;
    font-weight: 600;
    
  }

  a:hover {
    text-decoration-line: none;
    transform: translateY(-3px); /* Move the text up slightly */
    
  }

  
  
  .footer .Us{
    margin-top: 50px;
    font-size: medium;
  }
  
  .footer .num {
    margin-top: 10px;
    color: #f5f5f5;
    margin-bottom: 0px;
  
  }
  
  .footer .phone{
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
  
  .footer .mail{
    width: 20px;
    height: auto;
    margin-right: 10px;
  
  }

  .footer a li:hover{
    transform: translateY(-3px);
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    margin-top: 0px;
  
  }

  .sales{
    display: inline-block;
  }
  
  .footer a:hover {
    text-decoration: none;
    transform: translateY(-3px);
  }
  
  .social-icons a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons img{
    display: flex;
    justify-content: center;
    width: 20px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%)  
  }

  

  
  /* Responsive Design */
  
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
    }
  
    .footer-column {
      margin: 20px 0;
    }
  
    .social-icons i {
      font-size: 24px;
    }
  }
  
/* Footer section ends  */