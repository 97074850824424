body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;  
  }




/* Navbar section styling starts */
.nav{
  width: 100%;
  background-color:rgba(14, 34, 64, 1);
  box-sizing: border-box;
  border-bottom: 3px solid rgba(3, 214, 163, 1);
}


.navbar {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 7.5rem;
  background-color: rgba(14, 34, 64, 1);
  color: black;
  position: relative;
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
}

.navbar {
  background-color: rgba(14, 34, 64, 1);
  width: 100%;
  position: relative;
  box-sizing: border-box;
  
}

/* Content wrapper to center the navbar content */
.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px; /* Centering limit for large screens */
  margin: 0 auto; /* Center the content */
  width: 100%;
  
}



/* Styling for left section of the navbar */
.navbar-left h1 {
  margin: 0;
  font-size: 1.5rem;
  color: white;
}

.powered-by {
  font-size: 0.9rem;
  color: rgba(3, 214, 163, 1);
  font-weight: 600;
  margin: 0;
}

/* Styling for right section of the navbar */
.navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  
}

/* Styling for dropdown container */
.dropdown {
  /* position: relative; */
  margin-right: 1rem;
}

/* Styling for dropdown toggle button */
.dropdown-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  /* position: relative; */
}

/* Triangle for dropdown toggle */
.triangle {
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s ease;
  color: rgba(3, 214, 163, 1);
}



.rotate {
  transform: rotate(180deg); /* Rotate the triangle 180 degrees */
}

/* Dropdown menu styles for large screens */
.dropdown-menu {
  position: absolute;
  background-color: rgba(239, 239, 239, 0.8);
  padding:1rem 1rem;
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(4, 1fr); /* Four equal-width columns */
  gap: 1rem; /* Space between items */
  width: 100%; /* Full viewport width */
  left: 0; /* Align to the left edge of the viewport */
  top: 78px; /* Position just below the navbar */
  box-sizing: border-box; /* Include padding in width calculation */
  z-index: 9998; /* Ensure it is above other content */
}

/* Dropdown items styling */
.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: left; /* Align content to the left */
  text-decoration: none;
  color: rgba(14, 34, 64, 1);
  padding: 1rem; /* Adjust padding as needed */
  background-color: white;
  text-align: center;
  box-sizing: border-box; /* Include padding in width calculation */
  height: auto;
  overflow: hidden; /* Hide overflow to maintain size */
  border-radius: 10px;
}

/* Ensure images and text fit within item size */
.dropdown-item img {
  width: 40px; /* Adjust to fit the item size */
  height: auto; /* Maintain aspect ratio */
  margin-right: 0.5rem; /* Space between image and text */
  filter: brightness(0) saturate(100%) invert(17%) sepia(34%) saturate(623%) hue-rotate(175deg) brightness(100%) contrast(101%);

}

.dropdown-item span {
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
}

.build-park-btn {
  padding: 0.5rem 1rem;
  background-color:rgba(14, 34, 64, 1);
  border:2px solid rgba(3, 214, 163, 1);
  cursor: pointer;
  color:rgba(239, 239, 239, 1);
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;

}

/* Hamburger menu for small screens */
.hamburger {
  display: none;
  background:none;
  color: white;
  font-size: 2.5rem;
  cursor: pointer;
  font-weight: bolder;
  border: none;

}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-color:rgba(239, 239, 239, 1);
  z-index: 9999; /* Ensure it is above everything else */
  display: flex;
  flex-direction: column;
  overflow:scroll; /* Allow scrolling for hamburger menu content */
  align-items: center; /* Center items horizontally */
  padding-left: 15px;  
}

.close-btn {
  background: none;
  border: none;
  color:rgba(14, 34, 64, 1);
  font-size: 3.5rem; /* Increased size */
  cursor: pointer;
  align-self: flex-end; /* Ensures it aligns to the top-right */
  margin-right: 2.5rem; /* Add some margin to move it away from the right edge */
  margin-top: 0rem; /* Add margin from the top */
  margin-bottom: 2rem;
  z-index: 10000; /* Ensure it stays on top */
}

.hamburger-dropdowns {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%; /* Full width of the screen */
  gap: 10px;  
}

/* Ensure dropdown menu styles for small screens are centered */
.hamburger-dropdowns .dropdown-menu.full-width {
  flex-direction: column; /* Stack items vertically */
  width: 100%; /* Full width of the screen */
  height: auto;
  text-align: center;
  position: static; /* Ensure static position for small screens */
  z-index: 9997; /* Ensure it is below the close button but above the overlay */
}

/* Responsive Design */

@media (max-width: 922px) {
  .navbar{
    padding: 5px 5px;
  }

  .navbar-left h1 {
    margin: 0;
    font-size: 1.3rem;
    color: white;

  }

  
  
  .powered-by {
    font-size: 0.8rem;
    color: rgba(3, 214, 163, 1);
    font-weight: 600;
    margin: 0;
  
  }

  .navbar-right {
    display: none;
  }

  .dropdown-toggle{
    color:rgba(14, 34, 64, 1);
    font-size: 1.3rem;
    font-weight: bold;
    /* width: 100px; */
    justify-items: auto;
    padding: 10px;
  }

  .hamburger {
    display: block;
  }

  /* Ensure dropdown menu styles for small screens are vertically aligned and centered */
  .dropdown-menu {
    width: 100%;
    padding-right: 30px;
    display: flex; /* Flex layout for small screens */
    flex-direction: column; /* Stack items vertically */
    position: absolute;
    top: 40px; /* Adjust top position to be below the navbar */
    left: 0; /* Align to the left edge */
    right:0; /* Align to the right edge */
    /* margin: 0 auto; */
    box-sizing: border-box; /* Include padding in width calculation */
    overflow-y: auto; /* Allow vertical scrolling if needed */
    max-height: calc(100vh); 
    z-index: 9997; /* Ensure it is below the overlay but above other content */
    border-right: 10px;

  }

  .dropdown-item {
    /* flex: 1 0 auto;  */
    display: block; /* Ensure items stack vertically */
    display: flex;
    justify-content:left;    
  }

  .dropdown-item img{
    width: 50px;
    height: auto;
    padding-right: 10px;
  }

  .build-park-btn {
    width: 200px;
    height: 50px;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }

  .social-icons1 a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons1 img{
    display: flex;
    justify-content: center;
    width: 30px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%);  
  } 
}

/* Navbar section ends  */

/* why section starts  */
.why {
  width: 100%;
  background-color:white;
  box-sizing: border-box;
}

.why-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px; /* Limit width for large screens */
  margin: 0 auto;
  gap: 50px; /* Gap between text and image */
  padding: 50px 0px;
  
}

.head1 {
  flex: 1;
  color:rgba(14, 34, 64, 1);
  padding: 0 20px; /* Padding inside text content */
}

.head1 h2 {
  font-size: 36px;
  margin-bottom: 15px;
  padding-top: 0px;
  margin-top: 0px;
}

.custom-line {
  border: none;
  border-top: 4.5px solid rgba(3, 214, 163, 1);
  margin: 10px 0px;
  width: 450px;
  
  
}

.head1 p1 {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  display: block;
  font-weight:600;
}

.unicode {
  padding: 10px 20px;
  background-color: rgba(3, 214, 163, 1);
  color: rgba(14, 34, 64, 1);
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
  
}

.unicode:hover{
  transform: translateY(-3px);

}

.img1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img1 img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  
  
}


  /* Modal Styles */
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index:9999; /* Ensure the modal is on top */
  }
  
  .modal-content {
    background-color:white; /* Background color for the modal */
    padding: 0px 20px;
    border-radius: 8px; /* Rounded corners */
    width: 90%;
    max-width: 500px; /* Max width of the modal */
    box-shadow: 0 4px 8px #0E2240; /* Box shadow for depth */
    padding-bottom: 20px;
   
  }
  
  .close {
    position: relative;
    left:470px;
    cursor: pointer;
    color:#0E2240; /* Color of the close icon */
    font-size: 30px;
    padding: 0px 5px;
    font-weight: bold;
    
  }

  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin: 10px 0 5px; /* Margin for labels */
    color: #0E2240;
    font-weight: 500;
  }
  
  input {
    padding: 10px;
    font-size: 16px; /* Adjust font size as needed */
    border: 1px solid #0E2240; /* Border for input fields */
    border-radius: 4px; /* Rounded corners */
    color: #0E2240;
    text-decoration-color: #0E2240;
    

  }

  input:hover{
    transform: translateY(-3px);
  }
  
  button[type='submitt'] {
    margin-top: 20px; /* Space between input fields and submit button */
    background-color:#03D6A3; /* Submit button background color */
    color:#0E2240; /* Submit button text color */
    font-weight: 500;
    font-size: 25px;
    padding: 10px 0px;
    border-radius: 10px;
    cursor: pointer;
  }

  .success-message{
    color: #0E2240;
    font-weight: 600;
    text-align: center;
  }

  .error-message{
    color: #0E2240;
    font-weight: 600;
    text-align: center;
  }
  

/* Responsive styles */

@media (max-width:1200px) {
  .why-wrapper {
    flex-direction: column;
    gap: 30px;
    text-align: center;
    padding: 20px 30px;
  }

  .custom-line{
    width: 700px;
    margin: 0px 30px;
  }

  .head1 {
    padding: 0;
  }

  .head1 h2 {
    font-size: 28px;
  }

  .head1 p1 {
    font-size: 18px;
  }

  .unicode {
    font-size: 14px;
    padding: 8px 16px;
  }

  .img1 img {
    max-width: 80%; /* Adjust image size on smaller screens */
  }

  .modal-content {
    width: 95%; /* Adjust modal width for smaller screens */
  }
  .close {
      position: relative;
      left:480px;
      cursor: pointer;
      color:#0E2240; /* Color of the close icon */
      font-size: 36px;
  
    }
}


@media (max-width: 768px) {
  .why-wrapper {
    flex-direction: column;
    gap: 30px;
    text-align: center;
    padding: 20px 40px;
  }

  .custom-line{
    width: 715px;
    margin: 0px 10px;
  }

  .head1 {
    padding: 0;
  }

  .head1 h2 {
    font-size: 28px;
  }

  .head1 p1 {
    font-size: 18px;
  }

  .unicode {
    font-size: 14px;
    padding: 8px 16px;
  }

  .img1 img {
    max-width: 80%; /* Adjust image size on smaller screens */
  }

  .modal-content {
    width: 95%; /* Adjust modal width for smaller screens */
  }

  .close {
      position: relative;
      left:480px;
      cursor: pointer;
      color:#0E2240; /* Color of the close icon */
      font-size: 36px;
  
    }
}


@media (max-width: 480px) {
  .why-wrapper {
    flex-direction: column;
    gap: 30px;
    text-align: center;
    padding: 20px 20px;
  }

  .custom-line{
    width: 330px;
  }

  .head1 {
    padding: 0;
  }

  .head1 h2 {
    font-size: 24px;
  }

  .head1 p1 {
    font-size: 16px;
    font-weight: 600;
  }

  .unicode {
    font-size: 18px;
    padding: 8px 16px;
  }

  .img1 img {
    max-width: 100%; /* Adjust image size on smaller screens */
  }

  .modal-content {
    width: 95%; /* Adjust modal width for smaller screens */
    margin: 0px 10px;
    box-shadow: none;
  }

  .close {
      position: relative;
      left:300px;
      cursor: pointer;
      color:#0E2240; /* Color of the close icon */
      font-size: 36px;
      
  
    }
}

/* why section ends  */

/* positive section starts  */
.positive {
  width: 100%;
  
}

.positive-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: url('../Assests/Eduposbackimg.png');
  background-size:100% 100%;
  background-repeat: no-repeat;
  justify-content:left;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  border-radius: 5px;
  
}

.head2 {
  text-align: center;
  color:white;
  margin-bottom: 40px;
}

.head2 h2 {
  font-size: 36px;
  font-weight: bold;
}

.pos-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two items per row */
  gap: 20px; /* Space between items */
}

.pos-item1 {
  display: flex;
  align-items: flex-start; /* Align the image and content at the top */
  border-radius: 10px;
  padding: 20px;
  /* box-shadow: 0 4px 0px rgba(14, 34, 64, 1); */
  gap: 20px; /* Space between image and content */
}

.pos-item1 img {
  width: 60px; /* Icon size */
  height: 60px;
  flex-shrink: 0; /* Prevent the image from shrinking */
  border-radius: 10px;
}

.pos-content {
  flex-grow: 1;
}

.pos-content h4 {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  color:rgba(14, 34, 64, 1); /* Darker text color */
}

.pos-content p {
  margin-top: 10px;
  font-size: 16px;
  color:rgba(14, 34, 64, 1); /* Subdued text color */
  line-height: 1.5;
  font-weight: 600;
}

/* Responsive styles */

@media (max-width: 922px) {
  .positive-wrapper{
    padding: 10px 0px;
  }
  .pos-item {
    grid-template-columns: 1fr; /* Stack items on small screens */
    justify-items: center; /* Center the items horizontally */
    
  }

  .pos-item1 {
    flex-direction: column; /* Stack the image and content vertically */
    align-items: center; /* Center both image and text */
    text-align: center; /* Center the text */
    box-shadow: none;
    width: 600px;
    margin: 0 auto;
    padding-right: 65px;
    padding-top: 10px;
    
   
  }

  .pos-item1 img {
    margin-bottom: 10px; /* Add space below the image */
  }

  .head2 h2 {
    font-size: 26px;
  }

  .head2{
    text-align: center;
  }

  .pos-content p{
    font-size: 18px;
  }
}


@media (max-width: 768px) {

  .positive-wrapper{
    padding: 10px 0px;
  }
  .pos-item {
    grid-template-columns: 1fr; /* Stack items on small screens */
    justify-items: center; /* Center the items horizontally */
    
  }

  .pos-item1 {
    flex-direction: column; /* Stack the image and content vertically */
    align-items: center; /* Center both image and text */
    text-align: center; /* Center the text */
    box-shadow: none;
    width: 600px;
    margin: 0 auto;
    padding-right: 65px;
    padding-top: 10px;
    
   
  }

  .pos-item1 img {
    margin-bottom: 10px; /* Add space below the image */
  }

  .head2 h2 {
    font-size: 28px;
  }

  .head2{
    text-align: center;
  }

  .pos-content p{
    font-size: 18px;
  }
}

@media (max-width: 480px) {

 
.positive-wrapper{
  padding: 10px 0px;
}
  .pos-item {
    grid-template-columns: 1fr; /* Stack items on small screens */
    justify-items: center; /* Center the items horizontally */
  
  }

  .pos-item1 {
    flex-direction: column; /* Stack the image and content vertically */
    align-items: center; /* Center both image and text */
    text-align: center; /* Center the text */
    justify-content: center;
    width: 300px;
    margin: 0 auto;
    box-shadow: none;
    padding: 10px 10px;
    
   
  }

  .pos-item1 img {
    margin-bottom: 10px; /* Add space below the image */
    width: 80px;
    height: auto;
  }

  .head2 h2 {
    font-size: 24px;
  }
}

/* positive section ends  */


/* sub section starts  */
 .sub {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:white;
  width: 100%;
}


.sub-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items */
  align-items: center;
  gap: 2rem; /* Add spacing between items */
  width: 100%;
  max-width: 1200px; /* Constrain the total width */
  margin: 0 auto;
  padding: 50px 0px;
}

.sub-item > div {
  background-color:rgba(14, 34, 64, 1); /* Dark background for cards */
  color:white;
  padding: 2rem 2rem;
  border-radius: 5px;
  text-align: center;
  width: 280px; /* Fixed width for each card */
  height: 220px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-top: 4rem; /* Space for circular icon */
  margin-top: 4rem; /* Space above each card */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
}

/* Circular image styling */
.sub-item img {
  background-color:rgba(14, 34, 64, 1);
  padding: 0.5rem;
  border-radius: 50%;
  position: absolute;
  top: -40px; /* Place image above the card */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  object-fit: contain;
  box-shadow: 2px 2px 0px white;
}

/* Title (h3) styling */
.sub-item h3 {
  margin-top: 0rem; /* Space between icon and title */
  font-size: 1.2rem;
  color: white; /* Green title color */
}

/* Paragraph (p1) styling */
.sub-item p1 {
  font-size: 1rem;
  line-height: 1.5;
  color:white; /* Light text */
}



@media screen and (max-width: 922px) {
  .sub-item {
      grid-template-columns: 1fr; /* Stack items on small screens */
      padding: 30px 20px;
  }

  .sub-item > div {
      width: 80%; /* Ensure full-width on small screens */
  }

  /* Adjust the size of the circle image on small screens */
  .sub-item img {
      width: 60px;
      height: 60px;
      top: -30px;
  }

  .sub-item h3 {
      margin-top: 2.5rem;
  }

  .sub-item p1{
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .sub-item {
      grid-template-columns: 1fr; /* Stack items on small screens */
      padding: 30px 20px;
  }

  .sub-item > div {
      width: 80%; /* Ensure full-width on small screens */
  }

  /* Adjust the size of the circle image on small screens */
  .sub-item img {
      width: 60px;
      height: 60px;
      top: -30px;
  }

  .sub-item h3 {
      margin-top: 2.5rem;
  }

  .sub-item p1{
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .sub-item {
      grid-template-columns: 1fr; /* Stack items on small screens */
      padding: 30px 20px;
  }

  .sub-item > div {
      width: 80%; /* Ensure full-width on small screens */
      height: auto;
  }

  /* Adjust the size of the circle image on small screens */
  .sub-item img {
      width: 60px;
      height: 60px;
      top: -30px;
      
  }

  .sub-item h3 {
      margin-top: 0rem;
     
    
    }
}
/* sub section ends   */

/* kindergarten section starts  */
.kinder{
  width: 100%;
  background-color:rgba(14, 34, 64, 1);
  box-sizing: border-box;
  
}

.kindergartens {
  max-width: 1520px;
  width: 100%;
  background-color:rgba(14, 34, 64, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
 
}


.fit{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction:column;
  align-items: center;
  font-size: 34px;
  font-weight: 600;
  padding-bottom:30px ;
  text-align: center;
 

}

.fit p1{
  width: 60%;
  font-size: 34px;
  padding: 40px 50px;
  color: white;
}

.fit img{
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
 
}

.kinder-item{
  width: 80%;
  display: flex;
  flex-direction:row;
  align-items: center;
  height: auto;
  background-color:rgba(14, 34, 64, 1);
  font-size: 24px;
  font-weight:600 ;
  padding: 30px 0px;
  

}

.kinder-item1{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: white;
  color: rgba(14, 34, 64, 1);
  transform: translateY(-3px);
  border-radius: 5px;
  
}

.kinder-item1 img{
  width: 300px;
  height: auto;
  
}

.kinder-item1 p1{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 10px;

}



@media screen and (max-width: 922px) {

  .fit p1{
    font-size: 28px;
    width: 90%;
  }

  .fit img{
    width: 100%;
    height:350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-top: 15px;
  }

  .kinder-item1 p1{
    font-size: 36px;

  }

  .kinder-item{
    padding: 50px 0px;
  }

 
}

@media screen and (max-width: 768px) {

  .fit p1{
    font-size: 28px;
    width: 90%;
  }

  .fit img{
    width: 100%;
    height:350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-top: 15px;
  }

  .kinder-item1 p1{
    font-size: 36px;

  }

  .kinder-item{
    padding: 50px 0px;
  }

 
}


@media screen and (max-width: 480px) {

  .fit p1{
    font-size: 24px;
    width: 90%;
  }

  .fit img{
    width: 100%;
    height:250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-top: 15px;
  }

  .kinder-item1 p1{
    font-size: 24px;

  }
}

/* kindergarten section ends  */

/* feature section starts  */

.featu{
  width: 100%;
  background-color:white;
  box-sizing: border-box;

}

.feature{
  max-width: 1520px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 30px 0px;
}

.features{
  width: 100%;
  text-align: center;
  padding: 30px 0px;
}

.features p1{
  font-size: 34px;
  font-weight: 600;
  color: rgba(14, 34, 64, 1);
}

.features-item{
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color:white;
  gap: 10px;
  padding: 20px 0px;

}

.features-item1{
  display: flex;
  flex-direction: column;
  justify-content:left;
  align-items:left;
  background-color: rgba(14, 34, 64, 1);
  color: white;
  padding: 20px 20px;
  width: 20%;
  height:196.5px;
  border-radius: 5px;
  
}


.features-item2{
  display: flex;
  flex-direction: column;
  justify-content:left;
  align-items:left;
  background-color: rgba(14, 34, 64, 1);
  color: white;
  padding: 20px 20px;
  width: 20%;
  height: 196.5px;
  border-radius: 5px;
  
}

.features-item3{
  display: flex;
  flex-direction: column;
  justify-content:left;
  align-items: left;
  background-color: rgba(14, 34, 64, 1);
  color: white;
  padding: 20px 20px;
  width: 20%;
  height:196.5px;
  border-radius: 5px;
  
}

.features-item4{
  display: flex;
  flex-direction: column;
  justify-content:left;
  align-items:left;
  background-color: rgba(14, 34, 64, 1);
  color: white;
  padding: 20px 20px;
  width: 20%;
  height:196.5px;
  border-radius: 5px;
  
}

.features-item1 img{
  width: 20%;
  height: auto;
}

.features-item2 img{
  width: 21%;
  height: auto;
}


.features-item3 img{
  width: 20.5%;
  height: auto;
}


.features-item4 img{
  width: 19.5%;
  height: auto;
}

.features-item1 h4{
  color: rgba(3, 214, 163, 1);
  
}

.features-item2 h4{
  color: rgba(3, 214, 163, 1);
  
}

.features-item3 h4{
  color: rgba(3, 214, 163, 1);
  
}

.features-item4 h4{
  color: rgba(3, 214, 163, 1);
  
}

/* Responsive adjustments for tablets (768px and below) */

@media screen and (max-width: 922px) {

  .kinder-item {
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
  }

  .kinder-item1 img {
    width: 100%; /* Make the image responsive */
  }

  .features {
    padding: 20px 20px; /* Reduce padding */
  }


  .features-item {
    flex-direction: column; /* Stack features vertically */
    gap: 20px;
  }

  .features-item1, .features-item2, .features-item3, .features-item4 {
    width: 100%; /* Full width on small screens */
  }

  .features-item1 p1{
    font-size: 18px;
  }

  .features-item1 img, .features-item2 img, .features-item3 img, .features-item4 img {
    width: 10%; /* Adjust image width for smaller screens */
  }
}



@media screen and (max-width: 768px) {

  .kinder-item {
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
  }

  .kinder-item1 img {
    width: 100%; /* Make the image responsive */
  }

  .features {
    padding: 20px 20px; /* Reduce padding */
  }


  .features-item {
    flex-direction: column; /* Stack features vertically */
    gap: 20px;
  }

  .features-item1, .features-item2, .features-item3, .features-item4 {
    width: 100%; /* Full width on small screens */
  }

  .features-item1 p1{
    font-size: 18px;
  }

  .features-item1 img, .features-item2 img, .features-item3 img, .features-item4 img {
    width: 10%; /* Adjust image width for smaller screens */
  }
}

/* Responsive adjustments for mobile screens (480px and below) */
@media screen and (max-width: 480px) {

  .features{
    width: 90%;
  }

  .features p1 {
    font-size: 24px; /* Reduce feature title font size for mobile */

  }

  .features-item {
    width: 80%;
    padding: 10px 30px;
  }

  .features-item1 img, .features-item2 img, .features-item3 img, .features-item4 img {
    width: 20%; /* Further adjust image size */
  }
}

/* feature section ends  */

/* educontact section starts */
.educon{
  width: 100%;
  background-color:rgba(14, 34, 64, 1);
  box-sizing: border-box;
  border-bottom: 3px solid rgba(3, 214, 163, 1);
  
}

.educontact{
  max-width: 1520px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color:rgba(14, 34, 64, 1) ;
  padding: 30px 0px;
}

.educonhead{
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  color: white;

}

.educontactform p1{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: white;
  padding-bottom: 10px;
}

.educontactform form{
  width: 500px;
  height:auto;
  gap: 20px;

  
}

.educontactform ::placeholder{
  color: rgba(14, 34, 64, 1);
  font-weight: 100;
}

.edusub{
  font-size: 24px;
  height: 50px;
  border-radius: 5px;
  color:rgba(14, 34, 64, 1);
  background-color: rgba(3, 214, 163, 1);
  cursor: pointer;
}

.success-message{
  text-align: center;
  
}

.error-message{
  text-align: center;
  
}


/* Responsive adjustments for form (tablets and mobile screens) */

@media screen and (max-width: 922px) {
  .educontactform form {
    width:500px; /* Make the form full width on smaller screens */
    padding: 0 20px; /* Add padding to prevent edges from touching */
  }
}



@media screen and (max-width: 768px) {
  .educontactform form {
    width:500px; /* Make the form full width on smaller screens */
    padding: 0 20px; /* Add padding to prevent edges from touching */
  }
}

@media screen and (max-width: 480px) {

.educonhead{
  font-size: 22px;
  text-align: center;
  width: 90%;
}

  .educontactform p1 {
    font-size: 20px; /* Adjust font size for smaller screens */
    padding-bottom: 8px;

  }

  .educontactform form{
    width: 325px;
    height:auto;
    gap: 20px;
  
    
  }

  .edusub {
    font-size: 18px; /* Reduce button font size for mobile */
    height: 45px;
  }
}

/* educontact section ends  */

  /* Footer section starts */
  .footer {
    background-color:rgba(14, 34, 64, 1);
    color: white;
    padding: 40px 20px;
    font-family: 'Montserrat';

  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1520px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    min-width: 220px;
    margin: 10px;
  }
  
  .footer h3 {
    margin: 0;
  }
  
  .footer .game{
    margin: 0;
    font-size: large;
  }
  
  .footer p {
    font-size: 14px;
    line-height: 1.6;
    color: #cdcaca;
    padding: 7px;
    font-weight: 600;
  }
  
  .footer .powered-by {
    margin: 0;
    color:rgba(3, 214, 163, 1);
    font-size:small;
    font-weight: 600;
  }
  
  .footer ul {
    list-style: none;
    padding: 5px;
    text-align: left;
    
  }
  
  .footer li {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px;
    color: #cdcaca;
    font-weight: 600;
    
  }

  a:hover {
    text-decoration-line: none;
    transform: translateY(-3px); /* Move the text up slightly */
    
  }

  
  
  .footer .Us{
    margin-top: 50px;
    font-size: medium;
  }
  
  .footer .num {
    margin-top: 10px;
    color: #f5f5f5;
    margin-bottom: 0px;
  
  }
  
  .footer .phone{
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
  
  .footer .mail{
    width: 20px;
    height: auto;
    margin-right: 10px;
  
  }

  .footer a li:hover{
    transform: translateY(-3px);
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    margin-top: 0px;
  
  }

  .sales{
    display: inline-block;
  }
  
  .footer a:hover {
    text-decoration: none;
    transform: translateY(-3px);
  }
  
  .social-icons a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons img{
    display: flex;
    justify-content: center;
    width: 20px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%)  
  }

  

  
  /* Responsive Design */
  
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
    }
  
    .footer-column {
      margin: 20px 0;
    }
  
    .social-icons i {
      font-size: 24px;
    }
  }
  
/* Footer section ends  */