body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal; 

}

html {
    animation: repaint 0.01s step-end 0s 1;
    overflow-x: hidden;
  }

/* navbar section starts */
.landing-navbar-container {
    width: 100%;
    background-color:#0E2240; /* Adjust background color as needed */
  }
  
  .landing-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px; /* Adjust padding as needed */
    max-width: 1200px; /* Center the content and set a max-width */
    margin: 0 auto; /* Center the container */
  }
  
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .title1 {
    font-size: 24px; /* Adjust size as needed */
    margin: 0;
    color: white;
    font-weight:bold;
  }
  
  .subtitle1 {
    font-size: 16px; /* Adjust size as needed */
    margin: 0;
    color:#03D6A3;
    font-weight: bold;
    
  }
  
  .right {
    display: flex;
    align-items: center;
  }
  
  .landingnavimg {
    width: 24px; /* Adjust size as needed */
    height: auto;
  }

  .landingnavimg:hover{
    transform: translateY(-3px);
  }
  
  /* Responsive Styles */
   /* Responsive Styles */
   @media (max-width: 922px) {
    .landing-navbar {
      padding: 10px 10px; /* Adjust padding for smaller screens */
    }
  
    .title1 {
      font-size: 24px; /* Adjust font size for smaller screens */
    }
  
    .subtitle1 {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  
    .landingnavimg {
      width: 20px; /* Adjust icon size for smaller screens */
    }
  }





  @media (max-width: 768px) {
    .landing-navbar {
      padding: 10px 10px; /* Adjust padding for smaller screens */
    }
  
    .title1 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .subtitle1 {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  
    .landingnavimg {
      width: 20px; /* Adjust icon size for smaller screens */
    }
  }

/* navbar section ends  */
  

/* video section starts */
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 0px;
  background-color: white;
}

.heading {
  margin-bottom: 20px;
}

.title2 {
  font-size: 36px;
  margin: 0;
  color: #0E2240;
}

.title2 span {
  margin: 5px;
  font-weight: bold;
}

.subtitle2 {
  font-size: 36px;
  margin: 0;
  line-height: 1.5;
  color: #0E2240;
}

.subtitle2 span {
  margin: 5px;
  color: #0E2240;
  font-weight: bold;
}

.video-wrapper {
  position: relative;
  max-width: 800px;
  width: 100%;
  height: auto;
  display: block;
  
}

.bannerphoto {
  width: 100%;
  height: auto;
  display: block;
  object-fit:cover;
  
}


  /* Responsive Styles */
  @media (max-width: 922px) {
    .banner {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .title2 {
      font-size: 26px; /* Adjust font size for smaller screens */
    }
  
    .subtitle2 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .photo {
      max-width: 100%; /* Ensure the video fits within smaller screens */
    }
  }




  @media (max-width: 768px) {
    .banner {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .title2 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .subtitle2 {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  
    .photo {
      max-width: 100%; /* Ensure the video fits within smaller screens */
    }
  }

/* video section ends  */

  
/* banner section starts */
.banner2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px 0px; /* Adjust padding as needed */
    background-color:#0E2240; /* Background color for the banner2 */
    margin: 0px;
   
    
  }
  
  .heading2 {
    margin-bottom: 20px; /* Space between heading and image */

  }
  
  .title3 {
    font-size: 36px; /* Adjust size as needed */
    margin: 0;
    color: #0E2240;
    color: white;
    padding-top: 30px;
  }

  .title3 span{
    font-weight: bold;
    color: #03D6A3;

  }
  
  .subtitle3 {
    font-size: 36px; /* Adjust size as needed */
    margin: 0;
    line-height: 1.5;
    color:white;
  }
  
  .photo2 {
    width: 100%;
    max-width: 1600px;
  }
  
  .bannerphoto2 {
    width: 100%;
    height: auto;
   
  }
  
  /* Responsive Styles */
 
 @media (max-width: 922px) {
    .banner2 {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .title3 {
      font-size: 26px; /* Adjust font size for smaller screens */
    }
  
    .subtitle3 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .photo2 {
      max-width: 100%; /* Ensure the image fits within smaller screens */
    }
  }




  @media (max-width: 768px) {
    .banner2 {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .title3 {
      font-size: 18px; /* Adjust font size for smaller screens */
    }
  
    .subtitle3 {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  
    .photo2 {
      max-width: 100%; /* Ensure the image fits within smaller screens */
    }
  }
  
/* banner2 section ends  */

/* projector section starts */
.projector {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px 20px; /* Adjust padding as needed */
    background-color:#0E2240; /* Background color for the projector section */
    color: white;
    margin: 0;
  }
  
  .heading3 {
    margin-bottom: 20px; /* Space between heading and image */
  }
  
  .title4 {
    font-size: 36px; /* Adjust size as needed */
    font-weight: bold;
    margin: 0;
    
  }
  
  .subtitle4 {
    font-size: 36px; /* Adjust size as needed */
    margin: 0;
    line-height: 1.5;
    font-weight: bold;
  }

  .subtitle4 span{
    color: #03D6A3;
  }
  
  .photo3 {
    width: 100%;
    max-width:1200px; /* Max width for the image */
    
  }
  
  .bannerphoto3 {
    width: 100%;
    height: auto;
    border-radius: 10px;
    
  }
  
  /* Responsive Styles */
  @media (max-width: 922px) {
    .projector {
      padding: 20px 10px; /* Adjust padding for smaller screens */
      margin: 0;
    }
  
    .title4 {
      font-size: 26px; /* Adjust font size for smaller screens */
    }
  
    .subtitle4 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .photo3 {
      max-width: 100%; /* Ensure the image fits within smaller screens */
    }
  }



  @media (max-width: 768px) {
    .projector {
      padding: 20px 10px; /* Adjust padding for smaller screens */
      margin: 0;
    }
  
    .title4 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .subtitle4 {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  
    .photo3 {
      max-width: 100%; /* Ensure the image fits within smaller screens */
    }
  }

/* projector section ends  */

/* buzz section starts */
.buzz {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 40px 20px; /* Adjust padding as needed */
    background-color:#0E2240; /* Background color for the buzz section */
    color: white;
  }
  
  .photo4 video {
    width: 100%;
    max-width: 800px; /* Max width for the video */
    height: auto;
    transform-origin: center; 
  animation: swingEdges 5s infinite ease-in-out; 
    
  }

  /* Keyframes for swinging effect */
@keyframes swingEdges {
  0% {
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg); /* Start with no distortion */
  }
  25% {
    transform: perspective(1000px) rotateX(0deg) rotateY(5deg); /* Swing right edge */
  }
  50% {
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg); /* Return to normal */
  }
  75% {
    transform: perspective(1000px) rotateX(0deg) rotateY(-5deg); /* Swing left edge */
  }
  100% {
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg); /* Back to normal */
  }
}
  
  .text {
    margin-top: 20px; /* Space between video and text */
  }
  
  .brand {
    font-size: 24px; /* Adjust font size as needed */
    font-style: italic;
    margin: 0;
    padding: 0px 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  h2 {
    font-size: 24px; /* Adjust font size as needed */
    margin: 20px 0; /* Space above and below the heading */
  }
  
  .start {
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 24px; /* Adjust font size as needed */
    background-color:#0E2240; /* Button background color */
    color: #ffffff; /* Button text color */
    border: 3px solid #03D6A3;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;

  }
  
  .start:hover {
    background-color:#03D6A3; /* Darker button background on hover */
    color: #0E2240;
    transform: translateY(-3px);
  }
  
  .submit {
    font-size: 24px; /* Adjust font size as needed */
    margin-top: 10px;
    font-style: italic;
    
  }
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure the modal is on top */
  }
  
  .modal-content {
    background-color:white; /* Background color for the modal */
    padding: 0px 20px;
    border-radius: 8px; /* Rounded corners */
    width: 90%;
    max-width: 500px; /* Max width of the modal */
    box-shadow: 0 4px 8px #0E2240; /* Box shadow for depth */
    padding-bottom: 20px;
   
  }
  
  .close {
    position: relative;
    left:470px;
    cursor: pointer;
    color:#0E2240; /* Color of the close icon */
    font-size: 30px;
    padding: 0px 5px;
    font-weight: bold;
    
  }

  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin: 10px 0 5px; /* Margin for labels */
    color: #0E2240;
    font-weight: 500;
  }
  
  input {
    padding: 10px;
    font-size: 16px; /* Adjust font size as needed */
    border: 1px solid #0E2240; /* Border for input fields */
    border-radius: 4px; /* Rounded corners */
    color: #0E2240;
    text-decoration-color: #0E2240;
    

  }

  input:hover{
    transform: translateY(-3px);
  }
  
  button[type='submitt'] {
    margin-top: 20px; /* Space between input fields and submit button */
    background-color:#03D6A3; /* Submit button background color */
    color:#0E2240; /* Submit button text color */
    font-weight: 500;
    font-size: 25px;
    padding: 10px 0px;
    border-radius: 10px;
    cursor: pointer;
  }

  .success-message{
    color: #0E2240;
    font-weight: 600;
    text-align: center;
  }

  .error-message{
    color: #0E2240;
    font-weight: 600;
    text-align: center;
  }

  /* Responsive Styles */

  @media (max-width: 922px) {
    .buzz {
      padding: 20px 10px; /* Adjust padding for smaller screens */
      margin: 0;
    }
  
    .brand {
      font-size: 20px; /* Adjust font size for smaller screens */
      padding: 0px ;
    }
  
    h2 {
      font-size: 25px; /* Adjust font size for smaller screens */
    }
  
    button {
      padding: 8px 16px; /* Adjust padding for smaller screens */
      font-size: 18px; /* Adjust font size for smaller screens */
    }

    .submitt{
        font-size: 20px;
    }
  
    .modal-content {
      width: 95%; /* Adjust modal width for smaller screens */
    }
    .close {
        position: relative;
        left:480px;
        cursor: pointer;
        color:#0E2240; /* Color of the close icon */
        font-size: 36px;
    
      }
  }



  @media (max-width: 768px) {
    .buzz {
      padding: 20px 10px; /* Adjust padding for smaller screens */
      margin: 0;
    }
  
    .brand {
      font-size: 16px; /* Adjust font size for smaller screens */
      padding: 0px ;
    }
  
    h2 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    button {
      padding: 8px 16px; /* Adjust padding for smaller screens */
      font-size: 14px; /* Adjust font size for smaller screens */
    }

    .submit{
        font-size: 16px;
    }
  
    .modal-content {
      width: 95%; /* Adjust modal width for smaller screens */
    }

    .close {
        position: relative;
        left:480px;
        cursor: pointer;
        color:#0E2240; /* Color of the close icon */
        font-size: 36px;
    
      }
  }

  @media (max-width: 480px) {
    .buzz {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .brand {
      font-size: 16px; /* Adjust font size for smaller screens */
      padding: 0px ;
    }
  
    h2 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    button {
      padding: 8px 16px; /* Adjust padding for smaller screens */
      font-size: 14px; /* Adjust font size for smaller screens */
    }

    .submit{
        font-size: 16px;
    }
  
    .modal-content {
      width: 95%; /* Adjust modal width for smaller screens */
    }

    .close {
        position: relative;
        left:300px;
        cursor: pointer;
        color:#0E2240; /* Color of the close icon */
        font-size: 36px;
      }
  }

/* buzz section ends  */

/* home section starts */
.home {
    padding: 40px 20px; /* Adjust padding as needed */
    background-color: #f0f0f0; /* Background color for the home section */
    text-align: center;
  }
  
  .heading4 {
    margin-bottom: 30px; /* Space between heading and items */
  }
  
  .heading4 h2 {
    font-size: 36px; /* Adjust size as needed */
    margin: 0;
    color: #0E2240;
  }
  
  .heading4 h4 {
    font-size: 36px; /* Adjust size as needed */
    font-style: italic;
    margin: 10px 0;
    color: #0E2240;
    font-weight:500;
  }
  
  .item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between items */
    
  }
  
  .item div {
    width: 150px; /* Adjust width as needed */
    height: 100px;
    text-align: center;
    background-color: #03D6A3;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0;
    transition: color 0.4s
    
  }

  
  .item img {
    width: 50px; /* Adjust image size as needed */
    height: auto;
    transition: color 0.3s;
    filter: brightness(0) saturate(100%) invert(17%) sepia(34%) saturate(623%) hue-rotate(175deg) brightness(100%) contrast(101%);
  }

  .item3 img{
    width: 75px;
    padding: 1px 0px;

  }

  .item4 img{
    width: 60px;
    padding: 1px 0px;

  }

  .item5 img{
    width: 60px;
    padding: 1px 0px;
    
  

  }
  
  .item h4 {
    margin-top: 20px; /* Space between image and text */
    font-size: 16px; /* Adjust size as needed */
    transition: color 0.3s;
    color: #0E2240;
  }

  /* Hover effect */
.item div:hover {
    background-color:#0E2240; /* New background color on hover */
    transform: translateY(-5px);
    
  }
  
  .item div:hover img {
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%);
  }
  
  .item div:hover h4 {
    color: #03D6A3; /* New text color on hover */
  }
  
  /* Responsive Styles */
  @media (max-width: 922px) {
    .home {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .heading4 h2 {
      font-size: 26px; /* Adjust font size for smaller screens */
    }
  
    .heading4 h4 {
      font-size: 24px; /* Adjust font size for smaller screens */
    }
  
    .item {
      display: flex;
      flex-direction: column; /* Stack items vertically on small screens */
      align-items: center;
      justify-content: center;
      
    }
  
    .item div {
      width: 60%; /* Full width for items on small screens */
      max-width: 300px; /* Max width to prevent items from being too wide */
      height: auto;
      padding: 10px 5px;
    }
  
    .item img {
      width: 80px; /* Adjust image size for smaller screens */
    }
  
    .item h4 {
      font-size: 22px; /* Adjust font size for smaller screens */
    }
  }



  @media (max-width: 768px) {
    .home {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .heading4 h2 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .heading4 h4 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .item {
      display: flex;
      flex-direction: column; /* Stack items vertically on small screens */
      align-items: center;
      justify-content: center;
      
    }
  
    .item div {
      width: 60%; /* Full width for items on small screens */
      max-width: 300px; /* Max width to prevent items from being too wide */
      height: auto;
      padding: 10px 5px;
    }
  
    .item img {
      width: 80px; /* Adjust image size for smaller screens */
    }
  
    .item h4 {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  }
  
/* home section ends  */

/* images section starts */
.item-img-background {
    width: 100%;
    background-color:#0E2240; /* Background color for the full-width section */
    padding: 20px 0; /* Padding for the full-width section */

  }
  
  /* Base Styles for Item Images Container */
  .item-img {
    max-width: 1920px; /* Max width for the content */
    margin: 0 auto; /* Centering the content */
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items horizontally */
    gap: 20px; /* Space between images */
    margin-top: 40px;
  }
  
  /* Item Images Styles */
  .item-img div {
    flex: 1 1 calc(30% - 40px); /* Adjust width for each item and include gaps */
    max-width: calc(30% - 40px); /* Ensure items don’t exceed their allocated space */
    box-sizing: border-box; /* Include padding and border in the element’s total width and height */
  }

  .item-img div:hover{
    transform: translateY(-3px);
  }
  
  .item-img img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners for images */
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .item-img div {
      flex: 1 1 calc(25% - 30px); /* Adjust for medium-sized screens */
      max-width: calc(25% - 30px);
    }
  }
  
  @media (max-width: 900px) {
    .item-img div {
      flex: 1 1 calc(33.33% - 20px); /* Adjust for smaller screens */
      max-width: calc(33.33% - 20px);
    }
  }
  
  @media (max-width: 600px) {
    .item-img div {
      flex: 1 1 100%; /* Stack items vertically on small screens */
      max-width: 100%;
      padding: 5px 20px;
    }
  }
  
/* images section ends  */

/* market section starts */

.market-wrapper{
    width: 100%;
    background-color: #0E2240;
    
}

.market {
    max-width: 1200px; /* Constrain the width of the content */
    margin: 0 auto; /* Center the content */
    padding: 20px 300px; /* Padding around the section */
    text-align: center; /* Center-align text and buttons */
    background-color:#0E2240; /* Background color for the section */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    color: white;
  }
  
  .market h2 {
    font-size: 36px; /* Font size for the heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .market button {
    background-color:#0E2240; /* Background color for buttons */
    color:white; /* Text color for buttons */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners for buttons */
    padding: 10px 20px; /* Padding inside buttons */
    margin: 5px; /* Margin around buttons */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 20px; /* Font size for buttons */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    border: 3px solid #03D6A3;
    width: 180px;
    font-weight: 600;
  }
  
  .market button:hover {
    background-color:#03D6A3; /* Darker background on hover */
    
  }
  
  .market button a {
    color: #fff; /* Text color for the link inside the button */
    text-decoration: none; /* Remove underline from link */
  }
  
  .market .submitbut {
    margin-top: 20px; /* Space above the submit text */
    font-size: 24px; /* Font size for submit text */
    color:white; /* Text color for submit text */
   
  
  }
  
  /* Responsive Styles */
  @media (max-width: 922px) {
    .market {
      padding: 15px; /* Reduced padding for smaller screens */
    }
  
    .market h2 {
      font-size: 22px; /* Smaller font size for heading */
    }
  
    .market button {
      font-size: 18px; /* Smaller font size for buttons */
      padding: 8px 16px; /* Smaller padding for buttons */
    }

    .market .submit{
        font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    .market {
      padding: 15px; /* Reduced padding for smaller screens */
    }
  
    .market h2 {
      font-size: 20px; /* Smaller font size for heading */
    }
  
    .market button {
      font-size: 14px; /* Smaller font size for buttons */
      padding: 8px 16px; /* Smaller padding for buttons */
    }

    .market .submit{
        font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .market h2 {
      font-size: 18px; /* Further reduced font size for heading */
    }
  
    .market button {
      font-size: 12px; /* Even smaller font size for buttons */
      padding: 6px 12px; /* Even smaller padding for buttons */
    }

    .market .submit{
        font-size: 18px;
    }

    .market .submitbut{
      font-size: 18px;
    }
  }

/* market section ends  */

/* faq section starts  */
.faq-wrapper {
  width: 100%; /* Full width of viewport */
  background-color: #0E2240; /* Background color for the full-width section */
  border-bottom: 10px solid #03D6A3;
}

.faq-container {
  max-width: 1600px; /* Max width for the content */
  margin: 0 auto; /* Centering the content */
  padding: 40px 100px; /* Padding inside the content area */
  background-color:#0E2240; /* Background color for the content area */
  text-align: center;
}

/* Base Styles for FAQ Section */
.faq-container h1 {
  font-size: 32px; /* Adjust size as needed */
  margin-bottom: 20px; /* Space below the heading */
  color: white;
}

.faq-item {
  margin: 10px 0; /* Space between FAQ items */
  /* border: 1px solid #ddd;  */
  border-radius: 0px; /* Rounded corners */
  overflow: hidden; /* Ensures content stays within borders */
  padding: 10px 0px;

}

.faq-item:hover{
  transform: translateY(-3px);
}

.faq-question {
  padding: 15px 75px; /* Padding inside the question section */
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  background-color:#445979dd; /* Background color for the question */
  display: flex;
  justify-content: space-between;
  align-items:center;
  color: white;

}

.faq-question.active {
  background-color:#445979dd;; /* Background color for active question */
  color: #03D6A3;
}

.arrow {
  font-size: 20px; /* Size of the arrow icon */
}

.faq-answer {
  padding: 15px; /* Padding inside the answer section */
  font-size: 24px; /* Adjust size as needed */
  background-color: #445979dd;; /* Background color for the answer */
  color: white;
}

/* Responsive Styles */
@media (max-width: 922px) {
  .faq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .faq-container h1 {
    font-size: 26px; /* Adjust font size for smaller screens */
  }

  .faq-question {
    font-size: 20px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
  }

  .arrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .faq-answer {
    font-size: 20px; /* Adjust font size for smaller screens */
  }
}



@media (max-width: 768px) {
  .faq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .faq-container h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .faq-question {
    font-size: 14px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
  }

  .arrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .faq-answer {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}



@media (max-width:480px) {
  .faq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .faq-container h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .faq-question {
    font-size: 14px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
    text-align: left;
  }

  .arrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .faq-answer {
    font-size: 14px; /* Adjust font size for smaller screens */
    text-align: left;
  }
}

/* faq section ends  */
  
/* information section starts */
.info-wrapper{
    width: 100%;
    background: url('../Assests/info-background.png');
    overflow: hidden;
}

.info {
    max-width: 1200px; /* Constrain the width of the content */
    margin: 0 auto; /* Center the content */
    padding: 20px; /* Padding around the section */
    /* background:url('../Assests/info-background.png'); */
    border-radius: 8px; /* Rounded corners */
    display: flex; /* Flexbox for layout */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    align-items: center; /* Center items vertically */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
  }
  
  .information {
    flex: 1; /* Grow to fill available space */
    padding-right: 20px; /* Space between text and image */
  }
  
  .information p {
    font-size: 16px; /* Font size for the paragraph */
    line-height: 1.6; /* Line height for better readability */
    color:#0E2240; /* Text color */
    font-weight:600;
    text-align: justify;
  }
  
  .info-pic {
    flex: 1; /* Grow to fill available space */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }
  
  .info-pic img {
    width: 100%; /* Make image responsive */
    max-width: 400px; /* Constrain image width */
    border-radius: 10px; /* Rounded corners */
    margin-bottom: 20px; /* Space below the image */
  }

  .info-pic img:hover{
    transform: translateY(-3px);
  }
  
  .ceo {
    display: flex;
    flex-direction: row; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }
  
  .ceo img {
    width: 80px; /* Constrain size of CEO image */
    border-radius: 50%; /* Round image */
    margin-bottom: 10px; /* Space below the image */
  }

 
  
  .ceo h3 {
    font-size: 30px; /* Font size for the CEO's name */
    color:#0E2240; /* Text color */
    text-align: center; /* Center-align text */
  }
  
  /* Responsive Styles */

  @media (max-width: 922px) {
    .info {
      flex-direction: column; /* Stack items vertically on smaller screens */
      padding: 15px; /* Reduced padding for smaller screens */
    }
  
    .information {
      padding-right: 0; /* Remove right padding on smaller screens */
      margin-bottom: 20px; /* Space below text on smaller screens */
      
    }

    .information p{
        font-size: 20px;
    }
  
    .info-pic img {
      max-width: 100%; /* Allow image to take full width on smaller screens */
    }
  
    .ceo h3 {
      font-size: 24px; /* Smaller font size for CEO's name */
    }
  }

  @media (max-width: 768px) {
    .info {
      flex-direction: column; /* Stack items vertically on smaller screens */
      padding: 15px; /* Reduced padding for smaller screens */
    }
  
    .information {
      padding-right: 0; /* Remove right padding on smaller screens */
      margin-bottom: 20px; /* Space below text on smaller screens */
    }
  
    .info-pic img {
      max-width: 100%; /* Allow image to take full width on smaller screens */
    }
  
    .ceo h3 {
      font-size: 16px; /* Smaller font size for CEO's name */
    }
  }
  
  @media (max-width: 480px) {
    .information p {
      font-size: 14px; /* Smaller font size for paragraph */
    }
  
    .ceo h3 {
      font-size: 14px; /* Further reduced font size for CEO's name */
    }
  }
  
/* information section ends  */