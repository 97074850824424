body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;  
  }


/* Navbar styling */

/* Navbar section styling starts */
.nav{
  width: 100%;
  background-color:rgba(14, 34, 64, 1);
  box-sizing: border-box;
  border-bottom: 3px solid rgba(3, 214, 163, 1);
}


.navbar {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 7.5rem;
  background-color: rgba(14, 34, 64, 1);
  color: black;
  position: relative;
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
}

.navbar {
  background-color: rgba(14, 34, 64, 1);
  width: 100%;
  position: relative;
  box-sizing: border-box;
  
}

/* Content wrapper to center the navbar content */
.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px; /* Centering limit for large screens */
  margin: 0 auto; /* Center the content */
  width: 100%;
  
}



/* Styling for left section of the navbar */
.navbar-left h1 {
  margin: 0;
  font-size: 1.5rem;
  color: white;
}

.powered-by {
  font-size: 0.9rem;
  color: rgba(3, 214, 163, 1);
  font-weight: 600;
  margin: 0;
}

/* Styling for right section of the navbar */
.navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  
}

/* Styling for dropdown container */
.dropdown {
  /* position: relative; */
  margin-right: 1rem;
}

/* Styling for dropdown toggle button */
.dropdown-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  /* position: relative; */
}

/* Triangle for dropdown toggle */
.triangle {
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s ease;
  color: rgba(3, 214, 163, 1);
}



.rotate {
  transform: rotate(180deg); /* Rotate the triangle 180 degrees */
}

/* Dropdown menu styles for large screens */
.dropdown-menu {
  position: absolute;
  background-color: rgba(239, 239, 239, 0.8);
  padding:1rem 1rem;
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(4, 1fr); /* Four equal-width columns */
  gap: 1rem; /* Space between items */
  width: 100%; /* Full viewport width */
  left: 0; /* Align to the left edge of the viewport */
  top: 78px; /* Position just below the navbar */
  box-sizing: border-box; /* Include padding in width calculation */
  z-index: 9998; /* Ensure it is above other content */
}

/* Dropdown items styling */
.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: left; /* Align content to the left */
  text-decoration: none;
  color: rgba(14, 34, 64, 1);
  padding: 1rem; /* Adjust padding as needed */
  background-color: white;
  text-align: center;
  box-sizing: border-box; /* Include padding in width calculation */
  height: auto;
  overflow: hidden; /* Hide overflow to maintain size */
  border-radius: 10px;
}

/* Ensure images and text fit within item size */
.dropdown-item img {
  width: 40px; /* Adjust to fit the item size */
  height: auto; /* Maintain aspect ratio */
  margin-right: 0.5rem; /* Space between image and text */
  filter: brightness(0) saturate(100%) invert(17%) sepia(34%) saturate(623%) hue-rotate(175deg) brightness(100%) contrast(101%);

}

.dropdown-item span {
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
}

.build-park-btn {
  padding: 0.5rem 1rem;
  background-color:rgba(14, 34, 64, 1);
  border:2px solid rgba(3, 214, 163, 1);
  cursor: pointer;
  color:rgba(239, 239, 239, 1);
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;

}

/* Hamburger menu for small screens */
.hamburger {
  display: none;
  background:none;
  color: white;
  font-size: 2.5rem;
  cursor: pointer;
  font-weight: bolder;
  border: none;

}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-color:rgba(239, 239, 239, 1);
  z-index: 9999; /* Ensure it is above everything else */
  display: flex;
  flex-direction: column;
  overflow:scroll; /* Allow scrolling for hamburger menu content */
  align-items: center; /* Center items horizontally */
  padding-left: 15px;
  
  

  
}

.close-btn {
  background: none;
  border: none;
  color:rgba(14, 34, 64, 1);
  font-size: 3.5rem; /* Increased size */
  cursor: pointer;
  align-self: flex-end; /* Ensures it aligns to the top-right */
  margin-right: 2.5rem; /* Add some margin to move it away from the right edge */
  margin-top: 0rem; /* Add margin from the top */
  margin-bottom: 2rem;
  z-index: 10000; /* Ensure it stays on top */
}

.hamburger-dropdowns {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%; /* Full width of the screen */
  gap: 10px;

  
  
}

/* Ensure dropdown menu styles for small screens are centered */
.hamburger-dropdowns .dropdown-menu.full-width {
  flex-direction: column; /* Stack items vertically */
  width: 100%; /* Full width of the screen */
  height: auto;
  text-align: center;
  position: static; /* Ensure static position for small screens */
  z-index: 9997; /* Ensure it is below the close button but above the overlay */
}

/* Responsive Design */

@media (max-width:1200px) {
  .navbar{
    padding: 5px 5px;
  }

  .navbar-left h1 {
    margin: 0;
    font-size: 1.3rem;
    color: white;

  }

  
  
  .powered-by {
    font-size: 0.8rem;
    color: rgba(3, 214, 163, 1);
    font-weight: 600;
    margin: 0;
  
  }

  .navbar-right {
    display: none;
  }

  .dropdown-toggle{
    color:rgba(14, 34, 64, 1);
    font-size: 1.3rem;
    font-weight: bold;
    /* width: 100px; */
    justify-items: auto;
    padding: 10px;
  }

  .hamburger {
    display: block;
  }

  /* Ensure dropdown menu styles for small screens are vertically aligned and centered */
  .dropdown-menu {
    width: 100%;
    padding-right: 30px;
    display: flex; /* Flex layout for small screens */
    flex-direction: column; /* Stack items vertically */
    position: absolute;
    top: 40px; /* Adjust top position to be below the navbar */
    left: 0; /* Align to the left edge */
    right:0; /* Align to the right edge */
    /* margin: 0 auto; */
    box-sizing: border-box; /* Include padding in width calculation */
    overflow-y: auto; /* Allow vertical scrolling if needed */
    max-height: calc(100vh); 
    z-index: 9997; /* Ensure it is below the overlay but above other content */
    border-right: 10px;
  }

  .dropdown-item {
    /* flex: 1 0 auto;  */
    display: block; /* Ensure items stack vertically */
    display: flex;
    justify-content:left;  
  }

  .dropdown-item img{
    width: 50px;
    height: auto;
    padding-right: 10px;
  }

  .build-park-btn {
    width: 200px;
    height: 50px;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }

  .social-icons1 a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons1 img{
    display: flex;
    justify-content: center;
    width: 30px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%);  
  }

  
}
/* navbar section ends  */

/* museum1 section starts  */
.museum1 {
  width: 100%;
  height: auto;
  
}

.museumintro1 {
  max-width: 1520px;             /* Keep the max width */
  width: 100%;                   /* Full width */
  height: 500px;                 /* Fixed height */
  background: url('../Assests/muspic1.png') no-repeat center center/cover; /* Cover background */
  background-size: 100% 100%;
  margin: 0 auto;                /* Center horizontally */
  display: flex;                 /* Flexbox for layout */
  flex-direction: column;        /* Column layout */
  align-items: center;           /* Center content horizontally */
  justify-content: center;       /* Center content vertically */
  padding: 20px 0px;                 /* Padding for spacing */
}

.museumhead1 {
  background-color: rgba(14, 34, 64, 1);
  border-radius: 10px;
  padding: 20px;                 /* Add padding for better spacing */
  width: 60%;
  text-align: center;
}

.museumhead1 h3 {
  margin: 0;
  font-size:25px;               /* Default font size */  
  padding: 10px 20px;           /* Padding for the heading */
  color: rgba(3, 214, 163, 1);   /* Text color */
}

.museumhead1 h5 {
  margin: 0;
  font-weight: 500;              /* Font weight */
  padding-bottom: 10px;          /* Bottom padding */
  color: white;                  /* Text color */
  text-align: center;            /* Center text */
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .museumintro1 {
      height: 400px;             /* Reduce height on smaller screens */
  }

  .museumhead1{
    width: 80%;
  }

  .museumhead1 h3 {
      font-size: 20px;           /* Reduce font size on smaller screens */
  }

  .museumhead1 h5 {
      font-size: 16px;           /* Adjust font size for better readability */
  }
}

@media (max-width: 768px) {
  .museumintro1 {
      height: 300px;             /* Further reduce height on mobile */
      padding: 10px 0px;             /* Reduce padding */
  }

  .museumhead1 h3 {
      font-size: 20px;           /* Further reduce font size */
  }

  .museumhead1 h5 {
      font-size: 14px;           /* Adjust font size for mobile */
  }
}

@media (max-width: 480px) {
  .museumintro1 {
      height: 300px; /* Further reduce height on mobile */         
  }

  .museumhead1{
    width: 80%;
    
  }

  .museumhead1 h3 {
      font-size: 16px;           /* Further reduce font size */
      padding: 10px 0px;
  }

  .museumhead1 h5 {
      font-size: 12px;           /* Adjust font size for mobile */
  }
}
/* museum1 section ends  */

/* museum2 section starts  */
.museum2 {
  width: 100%;
  height: auto;
  
}

.museumintro2 {
  display: flex;
  flex-wrap: wrap;                 /* Allow items to wrap */
  align-items: center;             /* Center items vertically */
  justify-content: center;         /* Center items horizontally */
  max-width: 1520px;              /* Maximum width */
  width: 100%;                     /* Full width */
  margin: 0 auto;                 /* Centered horizontally */
  background-color: white;         /* Background color */

  
}

.museumhead2 {
  display: flex;                   /* Flex container */
  flex-wrap: wrap;                /* Allow wrapping */
  flex-direction: column;
  align-items: center;             /* Center items vertically */
  justify-content: center;         /* Center items horizontally */
  padding: 10px 5%;               /* Responsive padding */
}

.museumhead2 h3 {
  font-size: 34px;                /* Default font size */
  color: rgba(14, 34, 64, 1);     /* Text color */ 
  margin-bottom: 5px;             /* Bottom margin */
}

.museumhead2line {
  border: none;                    /* No border */
  border-top: 4.5px solid rgba(3, 214, 163, 1); /* Top border */
  margin: 10px 0;                 /* Vertical margin */
  width: 100%;                    /* Full width on smaller screens */
  max-width: 600px;               /* Max width for larger screens */
}

.museumhead2 h5 {
  margin: 10px;                   /* Margin for spacing */
  font-weight: 600;               /* Font weight */
  font-size: 18px;                /* Default font size */
  color: rgba(14, 34, 64, 1);
}

.museumcontent1 {
  display: flex;                  /* Flex container */
  flex-wrap: wrap;                /* Allow wrapping */
  align-items: center;            /* Center items vertically */
  justify-content: center;        /* Center items horizontally */
  gap: 100px;                      /* Gap between items */
  padding: 20px;                  /* Padding around content */
}

.museumcontent1 img {
  width: 100%;                    /* Full width for responsiveness */
  max-width:600px;              
  height: auto;                   /* Maintain aspect ratio */
  border-radius: 8px;
}

.museumcontent1 h3 {
  font-size: 24px;                /* Default font size */
  text-align: center;              /* Center text */
  color: rgba(14, 34, 64, 1);
}

.museumcontent1 h5 {
  font-size: 18px;                /* Default font size */
  text-align: center;              /* Center text */
  font-weight: 500;               /* Font weight */
  color: rgba(14, 34, 64, 1);
}

.museumcontent1 button {
  font-size: 24px;                /* Font size */
  width: 100%;                    /* Full width for buttons */
  max-width: 250px;               /* Max width */
  border-radius: 5px;             /* Rounded corners */
  color: white;                   /* Text color */
  background-color: rgba(3, 214, 163, 1); /* Background color */
  display: block;                 /* Block display */
  margin: 0 auto;                /* Center horizontally */
  border: none;                   /* No border */
  font-weight: 550;
  padding: 10px 5px;
}

.museumcontent1 button:hover {
  transform: translateY(-3px);    /* Hover effect */
  cursor: pointer;                 /* Pointer cursor */
}

.museumcontent1 a {
  font-size: 22px;                /* Font size */
  text-align: center;              /* Center text */
  text-decoration: none;           /* No underline */
  color: #0057FF;                   /* Link color */
  display: block;                 /* Block display */
  align-items: center;            /* Center items */
  font-weight: 600;               /* Font weight */
  margin: 0 auto;                /* Center horizontally */
  cursor: pointer;
}

.museumcontent1 h6 {
  text-align: center;              /* Center text */
  font-size: 18px;                /* Font size */
  font-weight: 500;               /* Font weight */
  color: rgba(14, 34, 64, 1);
}

/* Responsive Design */
@media (max-width: 1200px) {
  
  .museumhead2 h3 {
      font-size:30px;            /* Adjust font size for smaller screens */
      text-align: center;
  }

  .museumhead2 h5 {
      font-size: 16px;            /* Adjust font size for smaller screens */
  }

  .museumcontent1 {
      gap: 10px;                  /* Reduce gap between items */
  }

  .museumcontent1 img {
      max-width: 550px;           /* Smaller max width for images */
  }

  .museumcontent1 button {
      max-width: 250px;           /* Smaller max width for buttons */
  }
}

@media (max-width: 768px) {
  .museumhead2 h3 {
      font-size: 24px;            /* Further reduce font size */
  }

  .museumhead2 h5 {
      font-size: 14px;            /* Further reduce font size */
  }

  .museumcontent1 {
      flex-direction: column;      /* Stack items vertically */
      align-items: center;         /* Center items */
  }

  .museumcontent1 img {
      max-width: 90%;            /* Full width images */
  }

  .museumcontent1 h3, .museumcontent1 h5, .museumcontent1 h6 {
      font-size: 16px;            /* Adjust font size for smaller screens */
  }

  .museumcontent1 button {
      max-width: 100%;            /* Full width for buttons */
  }
}

@media (max-width: 480px) {

  .museumhead2 h3 {
      font-size: 20px;            /* Further reduce font size */
      text-align: center;
  }

  .museumhead2 h5 {
      font-size: 14px;            /* Further reduce font size */
      text-align: center;
  }

  .museumcontent1 {
      flex-direction: column;      /* Stack items vertically */
      align-items: center;         /* Center items */
  }

  .museumcontent1 img {
      max-width: 90%;            /* Full width images */
  }

  .museumcontent1 h3, .museumcontent1 h5, .museumcontent1 h6 {
      font-size: 16px;            /* Adjust font size for smaller screens */
  }

  .museumcontent1 button {
      max-width: 100%;
      font-size: 16px;            /* Full width for buttons */
  }
}
/* museum2 section ends  */

/* museum3 section starts  */
.museum3 {
  width: 100%;
  height: auto;
}

.museumintro3 {
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
}

.museumhead3 h3 {
  text-align:left;
  margin-bottom: 20px;
  font-size: 34px;
  color:rgba(14, 34, 64, 1);
  margin: 0;
  padding: 0px 100px;
}

.museumcontent2 {
  display: flex;
  justify-content: space-between;  /* Ensures even spacing between the items */
  flex-wrap: wrap;                 /* Allows items to wrap if needed */
  gap: 20px;                       /* Adds spacing between items */
  padding-top: 30px;
  padding: 30px 100px;
  
}

.museumsubcontentitem1 {
  flex: 1 1 calc(10% - 10px);      /* Each item takes 20% of the row, minus gap */
  box-sizing: border-box;          /* Ensures padding and borders are included */
  text-align: center;              /* Centers the content */
  /* padding: 10px; */
  background-color:white;          /* Optional: Background for individual items */
  border-radius: 8px;              /* Optional: Rounded corners */
  /* box-shadow: 0 4px 8px rgba(14, 34, 64, 0.1);  */

}

.museumsubcontentitem1:hover{
  transform: translateY(-3px);
}

.museumsubcontentitem1 img {
  max-width: 100%;                 /* Ensures image fits within the container */
  height: auto;
  margin-bottom:5px;             /* Space between image and text */
  margin: 0;
  border-radius: 8px;
}

.museumsubcontentitem1 h5 {
  font-size: 16px;
  color:rgba(14, 34, 64, 1);
  margin-top: 10px;
  margin-bottom: 5px;
  
}

/* Responsive Design for Smaller Screens */

@media (max-width: 1200px) {

  .museumintro3{
    width: 100%;
  }
  .museumhead3 h3{
    padding:0px 80px ;
    font-size: 28px;
  }

  .museumcontent2{
    padding: 30px 80px;
    gap: 20px;
  }
  .museumsubcontentitem1 {
    flex:1 1 calc(50% - 20px);                /* Stacks items vertically on smaller screens */
    max-width: 50%;
    
  }

  .museumsubcontentitem1 h5{
    font-size: 18px;
  }

}


@media (max-width: 768px) {
  .museumintro3{
    width:100%;
  }

  .museumhead3 h3{
    font-size:26px;
    padding: 10px 60px;
  }

  .museumcontent2{
    padding: 20px 60px;
    gap: 20px;
  }

  .museumsubcontentitem1 {
    flex:1 1 calc(50% - 20px);   
    box-sizing: border-box;
    
   
  }

}

@media (max-width:480px) {
 .museumintro3{
  width: 100%;
 }

 .museumhead3 h3{
  font-size: 20px;
  text-align:center;
  padding: 10px 30px;
 }

 .museumcontent2{
  padding: 10px 30px;
  display: block;

 }

 .museumsubcontentitem1{
  flex: 1 1 calc(100% - 20px);
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
 
 }
 .museumsubcontentitem1 h5{
  font-size: 14px;
  margin-bottom: 25px;
  margin-top:0px;
  text-align: center;
 }

}
/* museum3 section ends  */

/* museum4 section starts  */
.museum4{
  width: 100%;
  height: auto;
}

.museumintro4{
  width: 100%;
  max-width: 1520px;
  margin:0 auto;

}

.museumhead4 h3{
  font-size: 34px;
  text-align: left;
  padding: 10px 100px;
  margin: 0;
  color: rgba(14, 34, 64, 1);

}


.museumcontent3{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;                       /* Adds spacing between items */
  padding-top: 30px;
  padding: 30px 100px;
  justify-content: left;
}

.museumsubcontentitem2{
  flex: 1 1 calc(10% - 10px);      /* Each item takes 20% of the row, minus gap */
  box-sizing: border-box;          /* Ensures padding and borders are included */
  text-align: center;              /* Centers the content */
  /* padding: 10px; */
  background-color:white;          /* Optional: Background for individual items */
  border-radius: 8px;              /* Optional: Rounded corners */
  /* box-shadow: 0 4px 8px rgba(14, 34, 64, 0.1);  */
  max-width: 25%;

}

.museumsubcontentitem2:hover{
  transform: translateY(-3px);
}


.museumsubcontentitem2 img{
  max-width: 100%;
  width: 100%;
  height: auto;
  margin-bottom: 10px;             /* Space between image and text */
  border-radius: 8px;              
}

.museumsubcontentitem2 h4{
  text-align:left;
  font-size: 19px;
  margin: 0;
  padding-bottom: 10px;
  padding: 10px 0px;
  color: rgba(14, 34, 64, 1);
}

.museumsubcontentitem2 h5{
  text-align: left;
  font-weight: 500;
  margin: 0;
  padding: 0px 0px;
  color:rgba(14, 34, 64, 1);
}

@media (max-width:1200px){
  .museumintro4{
    width: 100%;
  }

  .museumhead4 h3{
    font-size: 28px;
    padding: 10px 80px;
  }

  .museumcontent3{
    gap: 20px;
    padding: 20px 80px;

  }

  .museumsubcontentitem2{
    flex: 1 1 calc(50% - 10px);
    box-sizing: border-box;
    max-width: 50%;
  }

  .museumsubcontentitem2 h4{
    font-size: 20px;
  }

  .museumsubcontentitem2 h5{
    font-size: 18px;
  }

}


@media (max-width:768px){
  .museumintro4{
    width: 100%;
  }

  .museumhead4 h3{
    font-size: 30px;
    padding: 10px 60px;
  }

  .museumcontent3{
    gap: 20px;
    padding: 20px 60px;

  }

  .museumsubcontentitem2{
    flex: 1 1 calc(50% - 10px);
    box-sizing: border-box;
  }

  .museumsubcontentitem2 h4{
    font-size: 20px;
  }

  .museumsubcontentitem2 h5{
    font-size: 18px;
  }

}


@media (max-width:480px){
  .museumintro4{
    width: 100%;
  }

  .museumhead4 h3{
    font-size: 20px;
    padding: 10px 30px;
    text-align: center;
    padding-top:30px;
  }

  .museumcontent3{
    gap: 20px;
    padding: 20px 30px;
    padding-bottom: 30px;

  }

  .museumsubcontentitem2{
    flex: 1 1 calc(100% - 10px);
    box-sizing: border-box;
    max-width: 100%;
  }

  .museumsubcontentitem2 h4{
    font-size: 18px;
    margin: 0;
    text-align: center;
  }

  .museumsubcontentitem2 h5{
    font-size: 14px;
    margin: 0;
    text-align: center;
  }

}
/* museum4 section ends  */

/* museum5 section starts  */
.museum5 {
  width: 100%;
  height: auto;
}

.museumintro5 {
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
}

.museumhead5 h3 {
  text-align:left;
  margin-bottom: 20px;
  font-size: 34px;
  color:rgba(14, 34, 64, 1);
  margin: 0;
  padding: 0px 100px;
}

.museumcontent4 {
  display: flex;
  justify-content: space-between;  /* Ensures even spacing between the items */
  flex-wrap: wrap;                 /* Allows items to wrap if needed */
  gap: 20px;                       /* Adds spacing between items */
  padding-top: 30px;
  padding: 30px 100px;
  
}

.museumsubcontentitem3 {
  flex: 1 1 calc(10% - 10px);      /* Each item takes 20% of the row, minus gap */
  box-sizing: border-box;          /* Ensures padding and borders are included */
  text-align: center;              /* Centers the content */
  /* padding: 10px; */
  background-color:white;          /* Optional: Background for individual items */
  border-radius: 8px;              /* Optional: Rounded corners */
  /* box-shadow: 0 4px 8px rgba(14, 34, 64, 0.1);  */

}

.museumsubcontentitem3:hover{
  transform: translateY(-3px);
}

.museumsubcontentitem3 img {
  max-width: 100%;                 /* Ensures image fits within the container */
  height: auto;
  margin-bottom:5px;             /* Space between image and text */
  margin: 0;
  border-radius: 8px;
}

.museumsubcontentitem3 h5 {
  font-size: 16px;
  color:rgba(14, 34, 64, 1);
  margin-top: 10px;
  margin-bottom: 5px;
  
}

/* Responsive Design for Smaller Screens */

@media (max-width: 1200px) {

  .museumintro5{
    width: 100%;
  }
  .museumhead5 h3{
    padding:0px 80px ;
    font-size: 28px;
    padding-top: 20px;
  }

  .museumcontent4{
    padding: 20px 80px;
    gap: 20px;
  }
  .museumsubcontentitem3 {
    flex:1 1 calc(50% - 20px);                /* Stacks items vertically on smaller screens */
    max-width: 50%;
    
  }

  .museumsubcontentitem3 h5{
    font-size: 18px;
  }

}


@media (max-width: 768px) {
  .museumintro5{
    width:100%;
  }

  .museumhead5 h3{
    font-size:26px;
    padding: 10px 60px;
  }

  .museumcontent4{
    padding: 20px 60px;
    gap: 20px;
  }

  .museumsubcontentitem3 {
    flex:1 1 calc(50% - 20px);   
    box-sizing: border-box;
    
   
  }

}

@media (max-width:480px) {
 .museumintro5{
  width: 100%;
 }

 .museumhead5 h3{
  font-size: 20px;
  text-align:center;
  padding: 10px 30px;
 }

 .museumcontent4{
  padding: 10px 30px;
  display: block;

 }

 .museumsubcontentitem3{
  flex: 1 1 calc(100% - 20px);
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
 
 }
 .museumsubcontentitem3 h5{
  font-size: 14px;
  margin-bottom: 25px;
  margin-top:0px;
  text-align: center;
 }

}
/* museum5 section ends  */

/* museum6 section starts  */
.museum6 {
  max-width:100%;
  height: auto;
}

.museumintro6 {
  text-align: center;               /* Center text for the intro */
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0px;
}

.museumhead6 h3 {
  font-size: 28px;
  margin-bottom: 10px;
  color: rgba(14, 34, 64, 1);
  text-align: left;
  padding: 0px 100px;
  margin: 0;
}

.museumhead6 h6 {
  font-size: 18px;
  color:rgba(14, 34, 64, 1);
  margin-bottom: 20px;
  padding: 10px 100px;
  text-align: left;
  font-weight: 500;
  margin: 0;
}

.museumsubhead6 {
  max-width:300px;                 /* Set a maximum width */
  width: 100%;
  margin: 0 auto;                   /* Center the subhead */
  background-color:rgba(14, 34, 64, 1);           /* Dark background */
  color:white;                      /* White text */
  padding: 30px;                    /* Padding inside the subhead */
  border-radius: 10px;              /* Rounded corners */
  text-align: center;               /* Center the text */
  margin-top: 20px;                 /* Space above */
}

.museumsubhead6 h3 {
  font-size: 24px;
  margin-bottom: 10px;
  
}

.museumsubhead6 h5 {
  font-size: 18px;
  margin: 5px 0;
  font-weight: 500;
}

.museumsubhead6 button {
  background-color:rgba(3, 214, 163, 1);        /* Button color */
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 22px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease; /* Smooth hover effect */
  font-weight: 550;
}

.museumsubhead6 button:hover {
  transform: translateY(-3px);
}

/* Responsive Design */

@media (max-width: 1200px) {

  .museumhead6{
    max-width: 1200px;
    width: 100%;
  }

  .museumhead6 h3{
    padding: 5px 80px;
    font-size: 30px;
  }

  .museumhead6 h6{
    padding: 10px 80px;
    font-size: 18px
  }

  .museumsubhead6 {
    max-width:300px;
    padding: 20px;
  }

  .museumsubhead6 h3 {
    font-size: 24px;
  }

  .museumsubhead6 h5 {
    font-size: 16px;
  }

  .museumsubhead6 button {
    font-size: 18px;
    padding: 10px 20px;
  }
}


@media (max-width: 768px) {

  
  .museumhead6{
    max-width: 1200px;
    width: 100%;
  }

  .museumhead6 h3{
    padding: 5px 60px;
  }

  .museumhead6 h6{
    padding: 0px 60px;
  }


  .museumsubhead6{
    padding: 30px 100px;
  }

  .museumsubhead6 {
    max-width: 40%;
    padding: 20px;
  }

  .museumsubhead6 h3 {
    font-size: 24px;
  }

  .museumsubhead6 h5 {
    font-size: 16px;
  }

  .museumsubhead6 button {
    font-size: 18px;
    padding: 10px 20px;
  }
}


@media (max-width: 480px) {
   
  .museumhead6{
    max-width: 1200px;
    width: 100%;
  }

  .museumhead6 h3{
    font-size: 18px;
    padding: 0px 30px;
    text-align: center;
  }

  .museumhead6 h6{
    font-size: 14px;
    padding: 0px 30px;
    text-align: center;
  }


  .museumsubhead6{
    padding: 30px 30px;
  }

  .museumsubhead6 {
    max-width: 80%;
    padding: 20px;
  }

  .museumsubhead6 h3 {
    font-size: 22px;
  }

  .museumsubhead6 h5 {
    font-size: 16px;
  }

  .museumsubhead6 button {
    font-size: 18px;
    padding: 10px 20px;
  }
}
/* museum6 section ends  */

/* museum faq section starts  */
.museumfaq-wrapper {
  width: 100%; /* Full width of viewport */
  background-color: #0E2240; /* Background color for the full-width section */
  border-bottom: 3px solid #03D6A3;
}

.museumfaq-container {
  max-width: 1600px; /* Max width for the content */
  margin: 0 auto; /* Centering the content */
  padding: 40px 100px; /* Padding inside the content area */
  background-color:#0E2240; /* Background color for the content area */
  text-align: center;
}

/* Base Styles for FAQ Section */
.museumfaq-container h1 {
  font-size: 32px; /* Adjust size as needed */
  margin-bottom: 20px; /* Space below the heading */
  color: white;
}

.museumfaq-item {
  margin: 10px 0; /* Space between FAQ items */
  /* border: 1px solid #ddd;  */
  border-radius: 0px; /* Rounded corners */
  overflow: hidden; /* Ensures content stays within borders */
  padding: 10px 0px;

}

.museumfaq-item:hover{
  transform: translateY(-3px);
}

.museumfaq-question {
  padding: 15px 75px; /* Padding inside the question section */
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  background-color:#445979dd; /* Background color for the question */
  display: flex;
  justify-content: space-between;
  align-items:center;
  color: white;

}

.museumfaq-question.active {
  background-color:#445979dd;; /* Background color for active question */
  color: #03D6A3;
}

.museumarrow {
  font-size: 20px; /* Size of the arrow icon */
}

.museumfaq-answer {
  padding: 15px; /* Padding inside the answer section */
  font-size: 24px; /* Adjust size as needed */
  background-color: #445979dd;; /* Background color for the answer */
  color: white;
}

/* Responsive Styles */
@media (max-width:1200px) {
  .museumfaq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .museumfaq-container h1 {
    font-size: 26px; /* Adjust font size for smaller screens */
  }

  .museumfaq-question {
    font-size: 20px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
    text-align: left;
  }

  .museumarrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .museumfaq-answer {
    font-size: 20px; /* Adjust font size for smaller screens */
    text-align: left;
  }
}



@media (max-width: 768px) {
  .museumfaq-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .museumfaq-container h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .museumfaq-question {
    font-size: 14px; /* Adjust font size for smaller screens */
    display: flex;
    justify-content:space-between;
    padding: 10px 15px ;
  }

  .museumarrow {
    font-size: 18px; /* Adjust size of arrow icon for smaller screens */
  }

  .museumfaq-answer {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}
/* museum faq section ends */
 

  /* Footer section starts */
  .footer {
    background-color:rgba(14, 34, 64, 1);
    color: white;
    padding: 40px 20px;
    font-family: 'Montserrat';

  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1520px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    min-width: 220px;
    margin: 10px;
  }
  
  .footer h3 {
    margin: 0;
  }
  
  .footer .game{
    margin: 0;
    font-size: large;
  }
  
  .footer p {
    font-size: 14px;
    line-height: 1.6;
    color: #cdcaca;
    padding: 7px;
    font-weight: 600;
  }
  
  .footer .powered-by {
    margin: 0;
    color:rgba(3, 214, 163, 1);
    font-size:small;
    font-weight: 600;
  }
  
  .footer ul {
    list-style: none;
    padding: 5px;
    text-align: left;
    
  }
  
  .footer li {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px;
    color: #cdcaca;
    font-weight: 600;
    
  }

  a:hover {
    text-decoration-line: none;
    transform: translateY(-3px); /* Move the text up slightly */
    
  }

  
  
  .footer .Us{
    margin-top: 50px;
    font-size: medium;
  }
  
  .footer .num {
    margin-top: 10px;
    color: #f5f5f5;
    margin-bottom: 0px;
  
  }
  
  .footer .phone{
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
  
  .footer .mail{
    width: 20px;
    height: auto;
    margin-right: 10px;
  
  }

  .footer a li:hover{
    transform: translateY(-3px);
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    margin-top: 0px;
  
  }

  .sales{
    display: inline-block;
  }
  
  .footer a:hover {
    text-decoration: none;
    transform: translateY(-3px);
  }
  
  .social-icons a {
    margin-right: 10px;
    display: inline-flex;
    flex-direction: row;
    
  }

  .social-icons img{
    display: flex;
    justify-content: center;
    width: 20px;
    height:auto;
    filter: brightness(0) saturate(100%) invert(38%) sepia(62%) saturate(2073%) hue-rotate(142deg) brightness(101%) contrast(100%)  
  }

  

  
  /* Responsive Design */
  
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
    }
  
    .footer-column {
      margin: 20px 0;
    }
  
    .social-icons i {
      font-size: 24px;
    }
  }
  
/* Footer section ends  */